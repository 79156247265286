import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import type {IPlayerPoolFilters} from "data/types/filter";
import {HorseStatsFilter, TeamPosition} from "data/enums";
import type {ITeamCommon} from "data/types/team";
import {TEAM_HORSE_POSITIONS} from "data/constants";

export interface IPlayerPoolStore {
	openPlayerPool: () => void;
	closePlayerPool: () => void;
	togglePlayerPool: () => void;
	updateSelectedPosition: (team: ITeamCommon) => void;
	handleStatusChange: (value: IPlayerPoolFilters["status"]) => void;
	handleStatsChange: (value: IPlayerPoolFilters["stats"]) => void;

	get filters(): IPlayerPoolFilters;

	get selectedPosition(): TeamPosition;

	set selectedPosition(value: TeamPosition);

	get isOpen(): boolean;
}

@injectable()
export class PlayerPoolStore implements IPlayerPoolStore {
	@observable private _selectedPosition: TeamPosition = TeamPosition.FirstPlace;
	@observable private _filters: IPlayerPoolFilters = {
		status: "all",
		stats: HorseStatsFilter.Selected,
	};
	@observable private _isOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	get selectedPosition(): TeamPosition {
		return this._selectedPosition;
	}

	set selectedPosition(value: TeamPosition) {
		this._selectedPosition = value;
	}

	get filters(): IPlayerPoolFilters {
		return this._filters;
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	@action
	public updateSelectedPosition(team: ITeamCommon) {
		for (const position of TEAM_HORSE_POSITIONS) {
			const horseId = team[position];
			if (position !== this._selectedPosition && !horseId) {
				this._selectedPosition = position;
				break;
			}
		}
	}

	@action
	public handleStatusChange = (value: IPlayerPoolFilters["status"]) => {
		this._filters = {
			...this._filters,
			status: value,
		};
	};

	@action
	public handleStatsChange = (value: IPlayerPoolFilters["stats"]) => {
		this._filters = {
			...this._filters,
			stats: value,
		};
	};

	@action
	public openPlayerPool = () => {
		this._isOpen = true;
	};

	@action
	public closePlayerPool = () => {
		this._isOpen = false;
	};

	@action
	public togglePlayerPool = () => {
		this._isOpen = !this._isOpen;
	};
}
