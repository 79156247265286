import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {HorseStatsFilter} from "data/enums";
import type {IPlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import type {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import type {IPlayerPoolStatusFilter} from "data/types/filter";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRacesStore} from "data/stores/races/races.store";

export interface IPlayerPoolFiltersController extends ViewController {
	handleStatusChange: (event: SelectChangeEvent<unknown>) => void;
	handleStatsChange: (event: SelectChangeEvent<unknown>) => void;

	get i18n(): ILocalizationStore;

	get status(): IPlayerPoolStatusFilter;

	get isComplete(): boolean;

	get stats(): HorseStatsFilter;
}

@injectable()
export class PlayerPoolFiltersController implements IPlayerPoolFiltersController {
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayerPoolStore) private _playerPoolStore: IPlayerPoolStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get status(): IPlayerPoolStatusFilter {
		return this.playerPoolFilters.status;
	}

	get isComplete(): boolean {
		return this._racesStore.isRaceComplete;
	}

	get stats(): HorseStatsFilter {
		return this.playerPoolFilters.stats;
	}

	private get playerPoolFilters() {
		return this._playerPoolStore.filters;
	}

	public handleStatusChange = (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value as IPlayerPoolStatusFilter;
		this._playerPoolStore.handleStatusChange(value);
	};

	public handleStatsChange = (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value as HorseStatsFilter;
		this._playerPoolStore.handleStatsChange(value);
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => this._racesStore.isRaceComplete,
			() => this.checkFilter(),
			{fireImmediately: true}
		);
		this.subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	private checkFilter() {
		const isPositionSelected =
			this._playerPoolStore.filters.stats === HorseStatsFilter.Position;
		if (this._racesStore.isRaceComplete) {
			this._playerPoolStore.handleStatsChange(HorseStatsFilter.Position);
		} else if (isPositionSelected) {
			this._playerPoolStore.handleStatsChange(HorseStatsFilter.Selected);
		}
	}
}
