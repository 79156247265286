import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IInviteLeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILeaguesApiProvider} from "data/providers/api/leagues.api.provider";

export interface ILeagueInvitesStore {
	fetchInvites(): Promise<void>;

	joinInvitedLeague(code: string): Promise<void>;

	cancelInvitedLeague(leagueId: number): Promise<void>;

	get list(): IInviteLeague[];
}

@injectable()
export class LeagueInvitesStore implements ILeagueInvitesStore {
	@observable private _list: IInviteLeague[] = [];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LeaguesApiProvider) private _leaguesApiProvider: ILeaguesApiProvider
	) {
		makeAutoObservable(this);
	}

	@action
	public async fetchInvites(): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.fetchInvites();

			runInAction(() => {
				this._list = data.success.invites;
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public async joinInvitedLeague(code: string): Promise<void> {
		try {
			await this._leaguesStore.joinToLeague({code});

			runInAction(() => {
				this._list = this.list.filter((e) => e.code !== code);
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public async cancelInvitedLeague(leagueId: number): Promise<void> {
		try {
			await this._leaguesApiProvider.removeInvite(leagueId);

			runInAction(() => {
				this._list = this.list.filter((e) => e.id !== leagueId);
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	get list(): IInviteLeague[] {
		return this._list;
	}
}
