import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITutorialStep, ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IModalTutorialController extends ViewController {
	onClose: () => void;
	onPlay: () => void;
	onReplay: () => void;
	goToFirstStep: () => void;
	goToStep: (currentSlide: number, nextSlide: number) => void;
	goToStepFromSteps: (currentSlide: number, nextSlide: number) => void;

	get i18n(): ILocalizationStore;

	get steps(): ITutorialStep[];

	get currentStep(): number;

	get isOpen(): boolean;

	get isStepsVisible(): boolean;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable private _currentStep: number = 0;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._tutorialStore.isTutorialOpen;
	}

	public get isStepsVisible(): boolean {
		const isFirstStep = this.currentStep === 0;
		const isLastStep = this.currentStep === this.steps.length + 1;
		return !(isFirstStep || isLastStep);
	}

	public get currentStep(): number {
		return this._currentStep;
	}

	public get steps(): ITutorialStep[] {
		return this._tutorialStore.steps;
	}

	private get user() {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		reaction(
			() => [this.user],
			() => this.checkModal(),
			{fireImmediately: true}
		);
	}

	onChange(param: void): void {
		return;
	}

	@action
	public onClose = (): void => {
		this._tutorialStore.closeTutorialModal();
		this._currentStep = 0;
	};

	@action
	public onPlay = (): void => {
		this._currentStep = 1;
	};

	@action
	public onReplay = (): void => {
		this._currentStep = 1;
	};

	@action
	public goToStep = (currentSlide: number, nextSlide: number): void => {
		runInAction(() => {
			this._currentStep = nextSlide;
		});
	};

	@action
	public goToStepFromSteps = (currentSlide: number, nextSlide: number): void => {
		this._currentStep = nextSlide;
	};

	@action
	public goToFirstStep = (): void => {
		this._currentStep = 1;
	};

	private checkModal(): void {
		if (!this.user) {
			return;
		}
		if (!this._tutorialStore.getIsTutorialViewedStorage()) {
			this._tutorialStore.openTutorialModal();
		}
	}
}
