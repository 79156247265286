import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IResetPasswordPayload} from "data/providers/api/password.api.provider";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";

interface IResetPasswordForm extends HTMLFormElement {
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IResetPasswordController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IResetPasswordForm>) => void;
	handleFormOnChange: () => void;

	togglePasswordVisibility: () => void;
	toggleConfirmPasswordVisibility: () => void;

	get i18n(): ILocalizationStore;

	get error(): Record<string, string> | null;

	get isSuccess(): boolean | undefined;

	get isFormDisabled(): boolean;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;
}

@injectable()
export class ResetPasswordController implements IResetPasswordController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _token = "";
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isPasswordVisible: boolean = false;
	@observable private _isConfirmPasswordVisible: boolean = false;
	@observable private _isChange: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isSuccess() {
		return this._requestState === RequestState.SUCCESS;
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action resetPassword(payload: IResetPasswordPayload, form: IResetPasswordForm) {
		this._requestState = RequestState.PENDING;

		this._userStore
			.resetPassword(payload)
			.then(() => this.onSuccess(form))
			.catch(this.onError);
	}

	@action changePassword(password: string, form: IResetPasswordForm) {
		this._requestState = RequestState.PENDING;

		this._userStore
			.update({password})
			.then(() => this.onSuccess(form))
			.catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<IResetPasswordForm>) => {
		event.preventDefault();
		const {password, confirmPassword} = event.currentTarget;

		if (!password.checkValidity()) {
			return this.reportError(PASSWORD_REQUIREMENTS, "password");
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError("Passwords do not match", "confirmPassword");
		}

		if (this._isChange) {
			void this.changePassword(password.value, event.currentTarget);
		} else {
			void this.resetPassword(
				{
					password: password.value,
					token: this._token,
				},
				event.currentTarget
			);
		}
	};

	init() {
		const searchParams = new URLSearchParams(window.location.search);
		this._token = searchParams.get("token") || "";
		this._isChange = window.location.href.includes("account");
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._errorMsg = extractErrorMessage(error);
	};

	@action private onSuccess = (form: IResetPasswordForm) => {
		this._requestState = RequestState.SUCCESS;
		this._userStore.isPasswordChanged = true;
		form.reset();
	};

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action
	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};
	@action
	public toggleConfirmPasswordVisibility = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};
}
