import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

export interface IMenuAccordionController extends ViewController {
	toggleAccordion: () => void;

	get isOpen(): boolean;
}

@injectable()
export class MenuAccordionController implements IMenuAccordionController {
	@observable private _isOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public toggleAccordion = () => {
		this._isOpen = !this._isOpen;
	};
}
