import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import {NO_TEAM_CODE} from "data/constants";
import type {IRivalTeamStore} from "data/stores/rival_team/rival_team.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IApiResponse} from "data/services/http";
import type {IRacePoints, ITeam} from "data/types/team";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRace} from "data/types/race";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {NavigateFunction} from "react-router-dom";

interface IParams {
	userId: number | undefined;
	raceId: number | undefined;
	navigate: NavigateFunction;
}

export interface IRivalTeamController extends ViewController<IParams> {
	get isLoading(): boolean;

	get i18n(): ILocalizationStore;

	get team(): ITeam | undefined;

	get username(): string | undefined;

	get race(): IRace | undefined;

	get points(): IRacePoints;

	get nextRaceId(): number | undefined;

	get prevRaceId(): number | undefined;

	get nextRaceClass(): string;

	get prevRaceClass(): string;
}

@injectable()
export class RivalTeamController implements IRivalTeamController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _raceId: number | undefined;
	@observable private _userId: number | undefined;
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.RivalTeamStore) private _rivalTeamStore: IRivalTeamStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._rivalTeamStore.isLoading;
	}

	get team() {
		return this._rivalTeamStore.team;
	}

	get race() {
		return this._racesStore.getRaceById(Number(this._raceId));
	}

	get username(): string | undefined {
		return this.team?.username || this.rankings?.displayName;
	}

	get points(): IRacePoints {
		return {
			racePoints: this.team?.points,
			totalPoints: this.rankings?.overallPoints,
			rank: this.rankings?.overallRank,
			raceRank: this.team?.raceRank,
		};
	}

	get rankings() {
		return this._rankingsStore.rankings.find((e) => e.userId === this._userId);
	}

	get nextRaceId() {
		return this.nextPrevRaces.nextRaceId;
	}

	get prevRaceId() {
		return this.nextPrevRaces.prevRaceId;
	}

	get nextRaceClass() {
		return this.nextRaceId ? "" : "disabled";
	}

	get prevRaceClass() {
		return this.prevRaceId ? "" : "disabled";
	}

	private get nextPrevRaces() {
		return this._racesStore.getPrevNextRaceAccordingCurrent(Number(this._raceId));
	}

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
		this._rivalTeamStore.clear();
	}

	init(param: IParams): void {
		this.initParams(param);

		const teamSubscription = reaction(
			() => [this._userId, this._raceId],
			() => this.fetchTeam(),
			{fireImmediately: true}
		);

		const raceSubscription = reaction(
			() => this._raceId,
			() => void this.fetchHorses(),
			{fireImmediately: true}
		);

		const userNameSubscription = reaction(
			() => [this.username, this.isLoading],
			() => this.checkTeamCompleteness(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(userNameSubscription);
		this.subscriptions$.push(teamSubscription);
		this.subscriptions$.push(raceSubscription);
	}

	onChange(param: IParams): void {
		this.initParams(param);
	}

	@action
	private initParams(param: IParams) {
		this._userId = param.userId;
		this._raceId = param.raceId;
		this._navigate = param.navigate;

		if (this._raceId) {
			this._rivalTeamStore.raceId = this._raceId;
		}
	}

	private fetchTeam() {
		if (!this._raceId || !this._userId) {
			return;
		}

		void this._rivalTeamStore
			.fetchTeam({
				raceId: this._raceId,
				userId: this._userId,
			})
			.catch((e) => {
				const error = e as AxiosError<IApiResponse>;
				if (error.response?.status === NO_TEAM_CODE) {
					this._rivalTeamStore.team = undefined;
					return;
				}
				this._modalsStore.showErrorModal(error);
			});
	}

	private async fetchHorses() {
		if (!this._raceId) {
			return;
		}
		try {
			this._rivalTeamStore.horses = await this._horsesStore.fetchHorsesPromise(this._raceId);
		} catch (e) {
			const error = e as AxiosError<IApiResponse>;
			this._modalsStore.showErrorModal(error);
		}
	}

	private checkTeamCompleteness() {
		if (!this.isLoading && !this.username) {
			this._navigate?.("/team");
		}
	}
}
