import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface ISettings {
	message: string;
	nextRace: string | null;
	celebrityLeagues: ICelebrityLeague[];
}

export interface ICelebrityLeague {
	leagueCode: string;
	leagueId: number;
	link: string;
	userId: number;
}

export interface ISettingsStore {
	get settings(): ISettings | undefined;

	get notification(): string | undefined;

	fetchSettings(): Promise<void>;
}

@injectable()
export class SettingsStore implements ISettingsStore {
	@observable private _settings: ISettings | undefined;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get settings(): ISettings | undefined {
		return this._settings;
	}

	get notification(): string | undefined {
		return this._settings?.message;
	}

	@action
	public async fetchSettings(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.settings();
			runInAction(() => {
				this._settings = data;
			});
		} catch (err: unknown) {
			return Promise.reject(err);
		}
	}
}
