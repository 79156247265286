import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";

export interface ITickerController extends ViewController {
	get sponsors(): string[];
}

@injectable()
export class TickerController implements ITickerController {
	constructor(@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore) {
		makeAutoObservable(this);
	}

	get sponsors(): string[] {
		return this._eventsStore.events.map((event) => event.logo).filter(Boolean);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._eventsStore.fetchEvents();
	}

	onChange(param: void): void {
		return;
	}
}
