import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRace} from "data/types/race";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {StorageHelper} from "data/utils/storage_helper";
import {ScrollHelper} from "data/utils/scroll_helper";

export interface ITeamActionsController extends ViewController {
	saveTeam: () => void;
	clearTeam: () => void;
	selectCaptain: () => void;

	get i18n(): ILocalizationStore;

	get teamHasNoChanges(): boolean;

	get isLockout(): boolean;

	get isCaptainSelectionAvailable(): boolean;

	get isSaveEnabled(): boolean;

	get isLoading(): boolean;

	get isCaptainPopoverVisible(): boolean;
}

@injectable()
export class TeamActionsController implements ITeamActionsController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _isCaptainPopoverVisible: boolean = false;
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get isCaptainPopoverVisible(): boolean {
		return this._isCaptainPopoverVisible;
	}

	get teamHasNoChanges(): boolean {
		return !this._teamStore.isTeamUpdated;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isSaveEnabled(): boolean {
		return this.isTeamFullFilled && this.isCaptainSelected;
	}

	get isCaptainSelectionAvailable(): boolean {
		if (this.isSaveEnabled) {
			return false;
		}
		return this.isTeamFullFilled;
	}

	get isLockout(): boolean {
		return this._racesStore.isRaceLocked;
	}

	private get isCaptainSelected(): boolean {
		return Boolean(this._teamStore.team.captain);
	}

	private get isTeamFullFilled(): boolean {
		return this._teamStore.isTeamHorseFull && this._teamStore.winningDistance !== undefined;
	}

	private get race(): IRace | undefined {
		return this._racesStore.selectedRace;
	}

	init(param: void) {
		const subscription = reaction(
			() => this.isCaptainSelectionAvailable,
			() => this.checkCaptainPopover(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);
	}

	dispose() {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	@action
	public saveTeam = () => {
		if (!this.race) {
			return;
		}

		this._isLoading = true;
		this._teamStore
			.saveTeam(this.race.id)
			.then(() => {
				this._modalsStore.showModal(ModalType.TEAM_SAVED);
			})
			.catch((error: AxiosError<IApiResponse>) => {
				this._modalsStore.showErrorModal(error);
			})
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	public clearTeam = () => {
		this._teamStore.clearTeam();
	};

	public selectCaptain = () => {
		this._isCaptainPopoverVisible = false;
		StorageHelper.SET(ScrollHelper.CAPTAIN_KEY, true);

		this._modalsStore.showModal(ModalType.CAPTAIN_PICK);
	};

	@action
	private checkCaptainPopover(): void {
		if (!this.isCaptainSelectionAvailable) {
			return;
		}
		const isStorageViewed = StorageHelper.GET(ScrollHelper.CAPTAIN_KEY);

		if (!isStorageViewed) {
			setTimeout(() => {
				this._isCaptainPopoverVisible = true;
			}, 500);
		}
	}
}
