import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import {
	TIE_BREAKER_MAX,
	TIE_BREAKER_MIN,
	TIE_BREAKER_NAMES,
	TIE_BREAKER_RANGE_MAPPER,
	TIE_BREAKER_STEP,
} from "data/constants";
import {get} from "lodash";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRacesStore} from "data/stores/races/races.store";
import {isNullOrUndefined} from "data/utils";
import {RaceStatus} from "data/enums";

export interface ITieBreakerController extends ViewController {
	setValue: (value: number | undefined) => void;
	increaseValue: () => void;
	decreaseValue: () => void;

	get i18n(): ILocalizationStore;

	get isVisible(): boolean;

	get value(): number | undefined;

	get preparedValue(): string | undefined;

	get isDecreaseDisabled(): boolean;

	get isIncreaseDisabled(): boolean;

	get isLocked(): boolean;

	get isScored(): boolean;

	get hasNoResult(): boolean;

	get isWinningDistanceCorrect(): boolean;
}

@injectable()
export class TieBreakerController implements ITieBreakerController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get hasNoResult(): boolean {
		return this._racesStore.selectedRace?.winningDistance === null;
	}

	get isScored(): boolean {
		if (!this._racesStore.selectedRace) {
			return false;
		}
		return [RaceStatus.Complete, RaceStatus.Abandoned].includes(
			this._racesStore.selectedRace.status
		);
	}

	get isWinningDistanceCorrect(): boolean {
		const userDistance = this._teamStore.winningDistance;
		const raceDistance = this._racesStore.selectedRace?.winningDistance;

		if (isNullOrUndefined(userDistance) || isNullOrUndefined(raceDistance)) {
			return false;
		}

		return userDistance === raceDistance;
	}

	get isIncreaseDisabled(): boolean {
		if (this.value === undefined) {
			return false;
		}
		return this.value >= TIE_BREAKER_MAX;
	}

	get isDecreaseDisabled(): boolean {
		if (this.value === undefined) {
			return true;
		}
		return this.value <= TIE_BREAKER_MIN;
	}

	get preparedValue(): string | undefined {
		if (this.value === undefined) {
			return this.i18n.t("tiebreaker.value.none", "None");
		}

		if (this.winningDistance > 10) {
			return this.i18n.t("tiebreaker.value.maximum", "10+ Lengths");
		}

		if (this.winningDistance > 1) {
			return this.i18n.t("tiebreaker.value.length", "{{X}} Lengths", {
				X: this.winningDistance,
			});
		}

		if (this.winningDistance >= 0.5) {
			return this.i18n.t("tiebreaker.value.length_singular", "{{X}} Length", {
				X: this.winningDistance,
			});
		}

		if (this.winningDistance <= 0) {
			const tieValue = get(TIE_BREAKER_RANGE_MAPPER, String(this.value)) as string;
			return this.i18n.t(get(TIE_BREAKER_NAMES, tieValue) as string);
		}

		return this.i18n.t(get(TIE_BREAKER_NAMES, this.winningDistance, ""));
	}

	get value(): number | undefined {
		return this._teamStore.winningDistance;
	}

	get isVisible(): boolean {
		return this._teamStore.isTeamHorseFull || this.isScored;
	}

	get isLocked(): boolean {
		return this._racesStore.isRaceLocked;
	}

	private get winningDistance(): number {
		return this._teamStore.winningDistance ?? TIE_BREAKER_MIN;
	}

	@action
	public increaseValue = () => {
		if (this.value === undefined) {
			this._teamStore.updateWinningDistance(TIE_BREAKER_MIN);
			return;
		}

		this._teamStore.updateWinningDistance(this.winningDistance + TIE_BREAKER_STEP);
	};

	@action
	public decreaseValue = () => {
		if (this.value === undefined) {
			this._teamStore.updateWinningDistance(TIE_BREAKER_MIN);
			return;
		}
		this._teamStore.updateWinningDistance(this.winningDistance - TIE_BREAKER_STEP);
	};

	@action
	public setValue = (value: number | undefined) => {
		this._teamStore.updateWinningDistance(value);
	};
}
