import React from "react";
import {observer} from "mobx-react";

import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import ShareIcon from "@mui/icons-material/Share";
import {Button, Popover} from "@mui/material";
import styled from "@emotion/styled";
import {TButtonType} from "data/types/ui";
import {ShareType} from "data/enums";
import {useViewController} from "data/services/locator";
import {ISharingController} from "views/components/sharing/sharing.controller";
import {Bindings} from "data/constants/bindings";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 12px;
	width: 100%;

	@media screen and (max-width: 820px) {
		gap: 12px;
	}
`;

const ShareContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 4px;
	overflow: hidden;

	button {
		padding: 4px;
	}
`;

const SharingButton = styled(Button)`
	min-width: unset;
	min-height: unset;
	width: 24px;
	height: 24px;
	background: transparent;
	border-radius: 4px;
	border: none !important;
	color: var(--primaryColorDark);

	&:focus,
	&:hover,
	&:active {
		background: transparent !important;
	}
`;

// const PopoverArrow = styled.div`
// 	position: absolute;
// 	left: 50%;
// 	bottom: 0;
// 	transform: translate(-50%, 100%);
//
// 	width: 0;
// 	height: 0;
// 	border-left: 8px solid transparent;
// 	border-right: 8px solid transparent;
// 	border-top: 8px solid white;
// `;

const HandlerButton = styled(Button)`
	min-height: unset;
`;

interface IProps {
	leagueId?: number;
	buttonVariant?: TButtonType;
	type: ShareType;
}

export const Sharing: React.FC<IProps> = observer(({type, buttonVariant = "text", leagueId}) => {
	const {
		isPopoverOpen,
		isMobileShareVisible,
		isNarrowScreen,
		anchorEl,
		shareHandler,
		shareViaTW,
		shareViaFB,
		beforeShareClose,
	} = useViewController<ISharingController>(Bindings.SharingController, {type, leagueId});

	if (isMobileShareVisible || isNarrowScreen) {
		return (
			<Wrapper>
				<HandlerButton aria-label="sharing" onClick={shareHandler} variant={buttonVariant}>
					<ShareIcon />
				</HandlerButton>

				<Popover
					className="overflow-popover"
					open={isPopoverOpen}
					anchorEl={anchorEl}
					onClose={beforeShareClose}
					anchorOrigin={{
						vertical: -8,
						horizontal: 150,
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}>
					<ShareContainer>
						<SharingButton onClick={shareViaFB}>
							<FacebookIcon />
						</SharingButton>
						<SharingButton onClick={shareViaTW}>
							<TwitterIcon />
						</SharingButton>
					</ShareContainer>
				</Popover>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<Button onClick={shareViaTW} variant={buttonVariant}>
				<TwitterIcon />
			</Button>
			<Button onClick={shareViaFB} variant={buttonVariant}>
				<FacebookIcon />
			</Button>
		</Wrapper>
	);
});
