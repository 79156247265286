import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const Input = styled(TextField)`
	background: transparent;
	color: var(--textFieldColorDefault);
	font-family: var(--fontFamilySecond);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.15px;
	border-radius: 4px;

	.MuiFilledInput-root {
		background: transparent;
		padding: 8px 0;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: var(--primaryColor);
	}

	.MuiInputAdornment-root {
		position: relative;
		right: 6px;
		bottom: 4px;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--primaryColor);
	}

	label {
		left: 12px;

		&[data-shrink="true"] {
			font-size: 0.9rem;
		}
	}

	.MuiFilledInput-root::before,
	.MuiFilledInput-root::after {
		border-radius: 3px;
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}

	input {
		padding: 0 12px 8px 12px;
		color: var(--primaryColor);
		font-weight: 400;
		font-family: var(--fontFamilySecond);
	}
`;

Input.defaultProps = {
	variant: "standard",
	fullWidth: true,
};
