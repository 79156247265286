import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";

export interface IHeaderController extends ViewController {
	toggleMenu: () => void;
	openTutorial: () => void;

	get i18n(): ILocalizationStore;

	get isAuthorized(): boolean;

	get isMenuOpen(): boolean;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.MenuStore) private readonly _menuStore: IMenuStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	public toggleMenu = () => {
		this._menuStore.toggleMenu();
	};

	public openTutorial = () => {
		this._tutorialStore.openTutorialModal();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
