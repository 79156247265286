import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {injectable} from "inversify";

export interface IMenuStore {
	get isMenuOpen(): boolean;

	closeMenu(): void;

	toggleMenu(): void;
}

@injectable()
export class MenuStore implements IMenuStore {
	constructor() {
		makeAutoObservable(this);
	}

	@observable private _isMenuOpen: boolean = false;

	get isMenuOpen(): boolean {
		return this._isMenuOpen;
	}

	set isMenuOpen(value: boolean) {
		this._isMenuOpen = value;
	}

	@action
	closeMenu(): void {
		runInAction(() => {
			this.isMenuOpen = false;
		});
	}

	@action
	public toggleMenu(): void {
		runInAction(() => {
			this.isMenuOpen = !this._isMenuOpen;
		});
	}
}
