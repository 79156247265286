import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {TIE_BREAKER_MAX, TIE_BREAKER_MIN, TIE_BREAKER_STEP} from "data/constants";

export interface IRangeSliderController extends ViewController {
	onDragStart: () => void;
	onDragEnd: () => void;
	getStepActiveClass: (step: number, value: number | undefined) => string;

	get min(): number;

	get max(): number;

	get step(): number;

	get steps(): number[];
}

@injectable()
export class RangeSliderController implements IRangeSliderController {
	constructor() {
		makeAutoObservable(this);
	}

	get step(): number {
		return TIE_BREAKER_STEP;
	}

	get steps(): number[] {
		const min = Math.abs(this.min);
		const max = Math.abs(this.max);
		const arrayLength = (min + max) / this.step;

		return Array(arrayLength)
			.fill(0)
			.map((item, index) => this.min + index * this.step);
	}

	get max(): number {
		return TIE_BREAKER_MAX;
	}

	get min(): number {
		return TIE_BREAKER_MIN;
	}

	public getStepActiveClass = (step: number, value: number | undefined) => {
		if (value === undefined) {
			return "";
		}
		return step <= value ? "active" : "";
	};

	public onDragStart = () => {
		setTimeout(() => {
			this.onDragEnd();
		}, 500);
	};

	public onDragEnd = () => {
		return;
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void) {
		return;
	}
}
