import {Bindings} from "data/constants/bindings";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IModalLeaveNavigateController extends ViewController {
	get i18n(): ILocalizationStore;
	get isBlocked(): boolean;
	stay: () => void;
	leave: () => void;
	close: () => void;
}

@injectable()
export class ModalLeaveNavigateController implements IModalLeaveNavigateController {
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public leave = (): void => {
		this._teamStore.clearTeam();
		this.close();
	};

	public stay = (): void => {
		this.close();
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	get isBlocked(): boolean {
		return this._teamStore.isTeamUpdated;
	}
}
