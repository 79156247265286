import styled from "@emotion/styled";

export const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 16px;
	border-radius: 4px;
	text-align: center;

	max-height: 80vh;
	overflow: auto;

	@media screen and (max-width: 640px) {
		width: calc(100% - 40px);

		&.full-screen {
			max-width: unset;
			max-height: unset;
			width: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: none;
			border-radius: 0;
		}
	}
`;

export const ModalLogoWrapper = styled.div`
	svg {
		max-height: 110px;
		width: auto;
	}
`;

export const ModalCloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	&.absolute {
		position: absolute;
		right: 12px;
		top: 12px;
	}

	button {
		padding: 0;
		width: 24px;
		height: 24px;
		min-height: 24px;
		-webkit-border-radius: 24px;
		-moz-border-radius: 24px;
		border-radius: 24px;

		svg {
			width: 16px;
			height: auto;
		}
	}
`;

export const ModalIcon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalTitle = styled.h2`
	color: var(--primaryColor);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	text-transform: capitalize;
	margin-bottom: 12px;
`;

export const ModalText = styled.p`
	color: var(--primaryColor);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 22.4px */

	b {
		font-weight: bold;
	}
`;

export const ModalHorsesWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;

export const ModalHorseBlock = styled.div`
	width: 100%;
	padding: 7px 8px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background: #ebe6d6;
	border-radius: 4px;
	gap: 8px;

	img {
		height: 100%;
		max-height: 48px;
		width: auto;
	}

	p {
		color: var(--primaryColorDark);
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		text-align: left;
	}

	button,
	div.focus {
		margin-left: auto;
	}
`;

export const ModalButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	gap: 12px;

	> button {
		width: 100%;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
`;
