import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IFederation} from "data/types/race";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface ISecondOptStore {
	get isModalOpen(): boolean;

	get federations(): IFederation[];

	get federationsForSave(): string[];

	get selectedFederations(): IFederation[];

	set selectedFederations(value: IFederation[]);

	openModal(): void;

	closeModal(): void;

	fetchFederations(): Promise<void>;
}

@injectable()
export class SecondOptStore implements ISecondOptStore {
	@observable private _isModalOpen: boolean = false;
	@observable private _federations: IFederation[] = [];
	@observable private _selectedFederations: IFederation[] = [];

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get federations(): IFederation[] {
		return this._federations;
	}

	@computed
	get federationsForSave(): string[] {
		return this._selectedFederations.map((federation) => federation.name);
	}

	get selectedFederations(): IFederation[] {
		return this._selectedFederations;
	}

	set selectedFederations(value: IFederation[]) {
		this._selectedFederations = value;
	}

	get isModalOpen(): boolean {
		return this._isModalOpen;
	}

	@action
	public closeModal(): void {
		this._isModalOpen = false;
	}

	@action
	public openModal(): void {
		this._isModalOpen = true;
	}

	@action
	public async fetchFederations(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.federations();

			if (!data || !Array.isArray(data)) {
				throw new Error("Error while loading federations");
			}

			runInAction(() => {
				this._federations = data;
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
