import React from "react";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {DONKEY_INSURANCE_TOTAL, TEAM_HORSE_POSITIONS} from "data/constants";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IHorse} from "data/types/race";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeam} from "data/types/team";
import type {IRacesStore} from "data/stores/races/races.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IModalDonkeyInsuranceController extends ViewController {
	close: () => void;
	pickDonkey: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeDonkey: () => void;
	getIsButtonDisabled: (horseId: number) => boolean;
	getIsPicked: (horseId: number) => boolean;
	getIsCaptain: (horseId: number) => boolean;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get horses(): IHorse[];

	get usedCount(): number;

	get totalCount(): number;
}

@injectable()
export class ModalDonkeyInsuranceController implements IModalDonkeyInsuranceController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get totalCount(): number {
		return DONKEY_INSURANCE_TOTAL;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.DONKEY_INSURANCE;
	}

	get horses(): IHorse[] {
		return this.horseIds
			.map((horseId) => this._horsesStore.getHorseById(horseId))
			.filter((horse) => horse) as IHorse[];
	}

	get usedCount(): number {
		if (this.team.donkeyInsurance === undefined) {
			return 0;
		}
		return DONKEY_INSURANCE_TOTAL - this.team.donkeyInsuranceAllowedCount;
	}

	private get team(): ITeam {
		return this._teamStore.team as ITeam;
	}

	private get horseIds(): number[] {
		return TEAM_HORSE_POSITIONS.map((position) => this._teamStore.team[position] || -1);
	}

	public getIsButtonDisabled = (horseId: number) => {
		if (horseId === this.team.captain) {
			return true;
		}
		return this.totalCount > 0 && this.usedCount >= this.totalCount;
	};

	public getIsPicked = (horseId: number) => {
		return horseId === this._teamStore.donkeyInsurance;
	};

	public getIsCaptain = (horseId: number) => {
		return horseId === this._teamStore.team?.captain;
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	@action
	public pickDonkey = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		const horseId = Number(event.currentTarget.dataset.id);
		if (!horseId || !this._racesStore.selectedRace) {
			return;
		}

		this._teamStore.applyDonkeyInsurance(horseId);
		this.close();
	};

	@action
	public removeDonkey = () => {
		this._teamStore.applyDonkeyInsurance(null);
	};
}
