import {action, computed, makeAutoObservable, observable} from "mobx";
import {EMAIL_REGEXP, FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {filter, get, identity, isEmpty, values} from "lodash";
import {injectable} from "inversify";
import {DateTime} from "luxon";

type THTMLFormElements = HTMLInputElement | HTMLSelectElement;

interface IErrorDictionary {
	required: string;
	default: string;
	password_mismatch: string;
	byFieldName: {
		terms: string;
		displayName: string;
		firstName: string;
		lastName: string;
		email: string;
		password: string;
		confirmPassword: string;
	};
}

export interface IFormValidationHelper {
	clearFormFieldError: (fieldName: string) => void;
	setFormFieldError: (fieldName: string, error?: string) => void;
	checkValidity: (form: HTMLFormElement) => boolean;

	get formErrors(): Record<string, string>;

	get isValid(): boolean;

	get errors(): IErrorDictionary;
}

@injectable()
export class FormValidationHelper implements IFormValidationHelper {
	@observable private _formErrors: Record<string, string> = {};

	constructor() {
		makeAutoObservable(this);
	}

	get formErrors() {
		return this._formErrors;
	}

	@computed get isValid() {
		return isEmpty(filter(values(this._formErrors), identity));
	}

	@computed
	public get errors() {
		const errorMsgPasswordInvalid = "register.password.helper";

		return {
			required: "form.error.required",
			default: "Please fill-in a correct value",
			password_mismatch: "form.error.password_no_match",
			byFieldName: {
				terms: "form.error.terms",
				displayName: "form.error.display_name",
				firstName: "form.error.first_name",
				lastName: "form.error.last_name",
				email: "form.error.email",
				confirmEmail: "form.error.email_confirm",
				password: errorMsgPasswordInvalid,
				confirmPassword: errorMsgPasswordInvalid,
				oldPassword: errorMsgPasswordInvalid,
				postcode: "form.error.postcode",
				dob: "form.error.dob",
				age: "form.error.age",
			},
		};
	}

	@action clearFormFieldError = (fieldName: string) => {
		this.setFormFieldError(fieldName);
	};

	@action setFormFieldError = (fieldName: string, error = "") => {
		this._formErrors[fieldName] = error;
	};

	checkValidity = (form: HTMLFormElement) => {
		const fields = Array.from(
			form.getElementsByClassName(FORM_VALIDATION_ELEMENT_CLASSNAME)
		) as unknown as THTMLFormElements[];

		fields.forEach((field) => {
			const element = field.parentNode?.querySelector("input") || field;

			if (element.tagName.toLowerCase() !== "input") {
				return true;
			}

			this.checkRequired(element);
			this.checkEmail(element);
			this.checkValid(element);
			this.checkDateOfBirth(element);
		});
		return this.isValid;
	};

	private checkRequired(field: THTMLFormElements): boolean {
		const {type, name, value} = field;
		const isCheckbox = type === "checkbox";
		const hasValue = isCheckbox ? (field as HTMLInputElement).checked : value;
		if (field.hasAttribute("data-required") && !hasValue) {
			this.setFormFieldError(name, this.errors.required);
			return false;
		}
		return true;
	}

	private checkEmail(field: THTMLFormElements): boolean {
		const {name, value} = field;
		const isCorrectEmail = new RegExp(EMAIL_REGEXP).test(value);
		if (name === "email" && !isCorrectEmail) {
			this.setFormFieldError(name, this.errors.byFieldName.email);
			return false;
		}
		return true;
	}

	private checkValid(field: THTMLFormElements): boolean {
		if (!field.validity.valid) {
			const msg = get(this.errors.byFieldName, field.name, this.errors.default);
			this.setFormFieldError(field.name, msg);
			return false;
		}
		return true;
	}

	private checkDateOfBirth(field: THTMLFormElements) {
		const {name, value} = field;
		if (name !== "dob") {
			return true;
		}

		const dateTime = DateTime.fromFormat(value, "dd/MM/yyyy");
		if (dateTime.startOf("day") > DateTime.now().startOf("day")) {
			this.setFormFieldError("dob", this.errors.byFieldName["dob"]);
			return false;
		}

		if (Math.abs(dateTime.diffNow("years").years) < 18) {
			this.setFormFieldError("dob", this.errors.byFieldName["age"]);
			return false;
		}

		return true;
	}
}
