import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button, FormControl} from "@mui/material";
import {ErrorText, Input} from "views/components/form";
import {useViewController} from "data/services/locator";
import {IFormLoginController} from "views/components/forms/form_login/form_login.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {NavLink} from "react-router-dom";
import {Notification} from "views/components/common";

const Wrapper = styled.div`
	max-width: 320px;
	width: 100%;
`;

const Title = styled.h2`
	color: var(--primaryColor);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	text-transform: capitalize;
	margin-bottom: 12px;
`;

const Action = styled.p`
	color: var(--primaryColorDark);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 22.4px */
	margin-top: 16px;

	a {
		font-weight: bold;
		text-decoration: underline;
	}
`;

const ForgotButton = styled(NavLink)`
	padding: 0;
	height: 16px;
	color: var(--primaryColorDark);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
	text-decoration-line: underline;
	margin: 8px 0 8px auto;
`;

const ActionButton = styled(Button)`
	padding: 0;
	height: 16px;
	color: var(--primaryColorDark);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
	text-decoration-line: underline;
	width: auto;
	text-align: left;
	margin: 0;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`;

const NotificationWrapper = styled.div`
	margin-top: 20px;
`;

export const FormLogin: React.FC = observer(() => {
	const {
		i18n,
		isLoading,
		errorMsg,
		isPasswordChanged,
		openRegister,
		handleFormSubmit,
		handleFormChange,
	} = useViewController<IFormLoginController>(Bindings.FormLoginController);

	return (
		<Wrapper>
			<Title>{i18n.t("modal.auth.login", "Log in to your account")}</Title>
			<Exist when={Boolean(errorMsg)}>
				<ErrorText>{errorMsg}</ErrorText>
			</Exist>
			<Form onSubmit={handleFormSubmit} onChange={handleFormChange}>
				<FormControl fullWidth>
					<Input
						label={i18n.t("login.email.label", "Email Address")}
						name="email"
						type="email"
						placeholder={i18n.t("login.email.placeholder", "Email Address")}
						required={true}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Input
						label={i18n.t("login.password.label", "Password")}
						name="password"
						type="password"
						placeholder={i18n.t("login.password.placeholder", "Password")}
						required={true}
					/>
				</FormControl>
				<ForgotButton to="/forgot-password">
					{i18n.t("login.password.forgot", "Forgot Password?")}
				</ForgotButton>

				<Button disabled={isLoading} type="submit">
					{i18n.t("login.action.submit", "Log In")}
				</Button>
				<Action>
					<span>{i18n.t("login.navigate.text", "New?")} </span>
					<ActionButton disabled={isLoading} variant="text" onClick={openRegister}>
						{i18n.t("login.navigate.action", "Register Now")}
					</ActionButton>
				</Action>
			</Form>
			<Exist when={isPasswordChanged}>
				<NotificationWrapper>
					<Notification>
						{i18n.t("login.note.password_changed", "Password has been updated.")}
					</Notification>
				</NotificationWrapper>
			</Exist>
		</Wrapper>
	);
});
