import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRankingsApiProvider} from "data/providers/api/rankings.api.provider";

export interface IGameBarData {
	userId: number | null;
	displayName: string;
	userCountry: string;
	leagueId: number | null;
	rank: number | null;
	points: number | null;
	overallRank: number | null;
	overallPoints: number | null;
}

export interface IGameBarStore {
	get isLoading(): boolean;

	get gameBar(): IGameBarData | undefined;

	fetchGameBar(): Promise<void>;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _gameBar: IGameBarData | undefined;
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get gameBar(): IGameBarData | undefined {
		return this._gameBar;
	}

	@action
	public async fetchGameBar(): Promise<void> {
		try {
			this._isLoading = true;
			const {data} = await this._rankingsApiProvider.gameBar();

			runInAction(() => {
				this._gameBar = data.success;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
		return Promise.resolve(undefined);
	}
}
