import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IPrevNextRaceIds, IRace} from "data/types/race";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {RaceStatus} from "data/enums";
import {find, findLast, last} from "lodash";
import {DateTime} from "luxon";

export interface IRacesStore {
	get list(): IRace[];

	get scheduledList(): IRace[];

	get completedList(): IRace[];

	get selectedRace(): IRace | undefined;

	set selectedRace(value: IRace | undefined);

	get isNextRaceExists(): boolean;

	get isPrevRaceExists(): boolean;

	get isRaceLocked(): boolean;

	get isRaceComplete(): boolean;

	get isLoading(): boolean;

	get isCMSRace(): boolean;

	get CMSRace(): IRace | undefined;

	fetchRaces(): Promise<void>;

	toPrevRace(): void;

	toNextRace(): void;

	getRaceById(id: number): IRace | undefined;

	getNextRacesAccordingDate(date: string | undefined): IRace[];

	getRaceByFeedId(feedId: number): IRace | undefined;

	getPrevNextRaceAccordingCurrent(raceId: number): IPrevNextRaceIds;

	addUnpreparedRace(race: IRace): void;
}

@injectable()
export class RacesStore implements IRacesStore {
	@observable private _isLoading: boolean = true;
	@observable private _list: IRace[] = [];
	@observable private _selectedRace: IRace | undefined;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get selectedRace(): IRace | undefined {
		return this._selectedRace || this.activeRace || this.CMSRace;
	}

	set selectedRace(value: IRace | undefined) {
		this._selectedRace = value;
	}

	get list(): IRace[] {
		return this._list;
	}

	get scheduledList(): IRace[] {
		return this.list.filter((race) => race.status === RaceStatus.Scheduled);
	}

	get completedList(): IRace[] {
		return this.list.filter((race) => race.status === RaceStatus.Complete);
	}

	get isNextRaceExists() {
		return Boolean(this.nextRace);
	}

	get isPrevRaceExists() {
		return Boolean(this.prevRace);
	}

	get isRaceLocked() {
		if (!this.selectedRace) {
			return true;
		}
		return this.selectedRace.status !== RaceStatus.Scheduled;
	}

	get isRaceComplete() {
		if (!this.selectedRace) {
			return false;
		}
		return this.selectedRace.status === RaceStatus.Complete;
	}

	get CMSRace(): IRace | undefined {
		return this._list.find((race) => !race.id && race.date);
	}

	get isCMSRace(): boolean {
		const race = this.selectedRace;
		if (!race) {
			return false;
		}
		return !race.id;
	}

	get nextRace(): IRace | undefined {
		const raceIndex = this._list.findIndex((e) => e.id === this.selectedRace?.id);
		// To avoid 0 index when (raceIndex === -1)
		if (raceIndex === -1) {
			return;
		}

		const nextIndex = raceIndex + 1;
		return this._list[nextIndex];
	}

	private get activeRace(): IRace | undefined {
		const serverList = this._list.filter((e) => Boolean(e.id));
		const active = serverList.find((e) => e.status === RaceStatus.Playing);
		const schedule = serverList.find((e) => e.status === RaceStatus.Scheduled);
		return active || schedule || last(serverList);
	}

	private get prevRace(): IRace | undefined {
		const raceIndex = this._list.findIndex((e) => e.id === this.selectedRace?.id);
		const nextIndex = raceIndex - 1;
		return this._list[nextIndex];
	}

	@action
	public async fetchRaces(): Promise<void> {
		try {
			this._isLoading = true;
			const {data} = await this._jsonProvider.races();

			if (!data || !Array.isArray(data)) {
				throw new Error("Error while loading races");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public getPrevNextRaceAccordingCurrent(raceId: number): IPrevNextRaceIds {
		const currentRaceIndex = this._list.findIndex((e) => e.id === raceId);
		if (currentRaceIndex === -1) {
			return {prevRaceId: undefined, nextRaceId: undefined};
		}

		const list = [...this._list];
		const prevRaces = list.slice(0, currentRaceIndex);
		const nextRace = find(list, (e) => e.status === RaceStatus.Complete, currentRaceIndex + 1);
		const prevRace = findLast(prevRaces, (e) => e.status === RaceStatus.Complete);

		return {prevRaceId: prevRace?.id, nextRaceId: nextRace?.id};
	}

	@action
	public toPrevRace() {
		const race = this.prevRace;
		if (race) {
			this._selectedRace = race;
		}
	}

	@action
	public toNextRace() {
		const race = this.nextRace;
		if (race) {
			this._selectedRace = race;
		}
	}

	public getRaceById(id: number) {
		return this._list.find((race) => race.id === id);
	}

	public getRaceByFeedId(feedId: number) {
		return this._list.find((race) => race.feedId === feedId);
	}

	public getNextRacesAccordingDate(date: string | undefined): IRace[] {
		if (!date) {
			return [];
		}
		const dateTime = DateTime.fromISO(date);
		if (!dateTime.isValid) {
			return [];
		}
		return this.list.filter((race) => {
			const raceDate = DateTime.fromISO(race.date);
			return raceDate > dateTime;
		});
	}

	@action
	public addUnpreparedRace(race: IRace) {
		const existsByName = this.list.find((e) => e.title === race.title);
		if (race.title && race.date && !existsByName) {
			this._list.push(race);
		}
	}
}
