import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRace} from "data/types/race";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import type {IEventsStore} from "data/stores/events/events.store";
import {RaceStatus} from "data/enums";

export interface IRoundSelectorController extends ViewController {
	openTutorial: () => void;
	toggleScheduleOpen: () => void;

	get i18n(): ILocalizationStore;

	get user(): IUser | undefined;

	get race(): IRace | undefined;

	get isCMSRace(): boolean;

	get calendarClass(): string;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get calendarClass() {
		return this._eventsStore.isScheduleOpen ? "active" : "";
	}

	get race(): IRace | undefined {
		return this._racesStore.selectedRace;
	}

	get isCMSRace(): boolean {
		return this._racesStore.isCMSRace;
	}

	get user(): IUser | undefined {
		return this._userStore.user;
	}

	init?(param: void): void {
		const subscription = reaction(
			() => [
				this._settingsStore.settings?.nextRace,
				this._eventsStore.events,
				this._racesStore.list,
			],
			() => this.checkRaces(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);
	}

	onChange?(param: void): void {
		return;
	}

	dispose?(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	public openTutorial = () => {
		this._tutorialStore.openTutorialModal();
	};

	@action
	public toggleScheduleOpen = () => {
		this._eventsStore.isScheduleOpen = !this._eventsStore.isScheduleOpen;
	};

	private checkRaces() {
		const nextRaceName = this._settingsStore.settings?.nextRace;
		const event = this._eventsStore.getEventByName(nextRaceName || "");
		const someLiveOrSchedule = this._racesStore.list.find((race) =>
			[RaceStatus.Scheduled, RaceStatus.Playing].includes(race.status)
		);

		if (!event || someLiveOrSchedule) {
			return;
		}

		const race: IRace = {
			id: 0,
			feedId: 0,
			title: event.name,
			status: RaceStatus.Scheduled,
			date: event.date,
			winningDistance: null,
			country: "",
			course: "",
			logo: null,
			grayscaleLogo: null,
		};

		this._racesStore.addUnpreparedRace(race);
		this._racesStore.toNextRace();
		this._horsesStore.clearHorses();
	}
}
