import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {LeaguePrivacy} from "data/enums";
import type {ILeague, IInviteLeague} from "data/stores/leagues/leagues.store";

export interface ICreateLeaguePayload {
	name: string;
	privacy: LeaguePrivacy;
}

export interface ILeagueIdPayload {
	leagueId: number;
}

interface IPaginationPayload {
	page?: number;
	limit?: number;
}

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & ILeagueIdPayload;

export interface ILeaguesForJoinPayload extends IPaginationPayload {
	search?: string;
}

export interface ILeagueInvitesPayload extends ILeagueIdPayload {
	invites: {email: string}[];
}

export interface IRemoveUserFromLeaguePayload extends ILeagueIdPayload {
	userId: number;
}

export interface ILeagueCodePayload {
	code: string;
}

export type ILeagueUsersPayload = ILeagueIdPayload & IPaginationPayload;

export interface ILeaguesPayload extends IPaginationPayload {
	privacy?: LeaguePrivacy;
}

export interface ILeagueManager {
	userId: number | null;
	displayName: string;
}

export interface ILeagueUser extends ILeagueManager {
	userId: number;
}

export type ILeagueByCodeResponse = IApiResponse<ILeague>;
type ILeagueResponse = IApiResponse<{league: ILeague}>;
type ILeagueInvitesResponse = IApiResponse<{invites: IInviteLeague[]}>;
type ILeaguesListResponse = IApiResponse<{
	leagues: ILeague[];
	pinned: ILeague[];
	nextPage: boolean;
}>;
type ILeagueUserListResponse = IApiResponse<{users: ILeagueUser[]; nextPage: boolean}>;

export interface ILeaguesApiProvider {
	createLeague: (params: ICreateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	updateLeague: (params: IUpdateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeaguesForJoin: (
		params: ILeaguesForJoinPayload
	) => Promise<AxiosResponse<ILeaguesListResponse>>;
	leaveLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	inviteUsersToLeague: (params: ILeagueInvitesPayload) => Promise<AxiosResponse<void>>;
	removeUserFromLeague: (params: IRemoveUserFromLeaguePayload) => Promise<AxiosResponse<void>>;
	joinToLeague: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeagueUsers: (
		params: ILeagueUsersPayload
	) => Promise<AxiosResponse<ILeagueUserListResponse>>;
	fetchMyLeagues: (params: ILeaguesPayload) => Promise<AxiosResponse<ILeaguesListResponse>>;
	fetchLeagueByCode: (
		params: ILeagueCodePayload
	) => Promise<AxiosResponse<ILeagueByCodeResponse>>;
	fetchInvites: () => Promise<AxiosResponse<ILeagueInvitesResponse>>;
	removeInvite: (inviteId: number) => Promise<AxiosResponse<unknown>>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	createLeague = (params: ICreateLeaguePayload) =>
		this._http.post<ILeagueResponse>("fantasy/league", params);

	updateLeague = ({leagueId, ...params}: IUpdateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`fantasy/league/${leagueId}`, params);

	fetchLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<ILeagueResponse>(`fantasy/league/${leagueId}`);

	fetchLeaguesForJoin = (params: ILeaguesForJoinPayload) =>
		this._http.get<ILeaguesListResponse>("fantasy/league/show-for-join", params);

	leaveLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<void>(`fantasy/league/${leagueId}/leave`);

	inviteUsersToLeague = ({leagueId, ...params}: ILeagueInvitesPayload) =>
		this._http.post<void>(`fantasy/league/${leagueId}/invite`, params);

	removeUserFromLeague = ({leagueId, userId}: IRemoveUserFromLeaguePayload) =>
		this._http.post<void>(`fantasy/league/${leagueId}/user/${userId}`);

	joinToLeague = ({code}: ILeagueCodePayload) =>
		this._http.post<ILeagueResponse>(`fantasy/league/${code}/join`);

	fetchLeagueUsers = ({leagueId, ...params}: ILeagueUsersPayload) =>
		this._http.get<ILeagueUserListResponse>(`fantasy/league/${leagueId}/league-users`, params);

	fetchMyLeagues = (params: ILeaguesPayload) =>
		this._http.get<ILeaguesListResponse>("fantasy/leagues", params);

	fetchLeagueByCode = ({code}: ILeagueCodePayload) =>
		this._http.get<ILeagueByCodeResponse>(`fantasy/league/show-by-code/${code}`);

	fetchInvites = () => this._http.get<ILeagueInvitesResponse>("fantasy/invites");

	removeInvite = (inviteId: number) =>
		this._http.post<unknown>(`fantasy/invites/remove/${inviteId}`);
}
