import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

export interface IModalPayload {
	title?: string;
	message: string;
	cancelText?: string;
	confirmText?: string;
	callback?: (payload?: unknown) => void;
}

export type TModalArguments =
	| [type: ModalType]
	| [type: ModalType, content: IModalPayload | unknown];

export interface IModalsStore {
	showModal(...args: TModalArguments): void;
	showErrorModal(error: AxiosError<IApiResponse>): void;
	hideModal(): void;
	get modal(): ModalType | null | unknown;
	get modalContent(): IModalPayload | null | unknown;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;
	@observable _modalContent: IModalPayload | null | unknown = null;

	get modal() {
		return this._visibleModal;
	}

	get modalContent() {
		return this._modalContent;
	}

	constructor() {
		makeAutoObservable(this);
	}

	public showErrorModal(error: AxiosError<IApiResponse>): void {
		this.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action showModal(...args: TModalArguments): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType;
		this._modalContent = content;
	}
}
