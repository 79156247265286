import type {SelectChangeEvent} from "@mui/material";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRace} from "data/types/race";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {action, computed, IReactionDisposer, makeAutoObservable, observable} from "mobx";

interface IParams {
	leagueId: number;
}

export interface ILeagueLeaderboardFilterController extends ViewController<IParams> {
	handleSelectChange: (event: SelectChangeEvent<unknown>) => void;

	get isLoading(): boolean;

	get i18n(): ILocalizationStore;

	get selectedRace(): number | undefined;

	get races(): IRace[];
}

@injectable()
export class LeagueLeaderboardFilterController implements ILeagueLeaderboardFilterController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _leagueId: number | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return !this.league;
	}

	get selectedRace(): number | undefined {
		if (this._rankingsStore.selectedRace === "overall") {
			return undefined;
		}
		return this._rankingsStore.selectedRace || undefined;
	}

	@computed
	get races(): IRace[] {
		if (!this.league) {
			return [];
		}
		return this._racesStore.getNextRacesAccordingDate(this.league?.createdAt);
	}

	private get league() {
		if (!this._leagueId) {
			return;
		}
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	public handleSelectChange = (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value as number;
		if (!value) {
			return;
		}
		this._rankingsStore.selectedRace = value;
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this._leagueId = param.leagueId;
		// const leagueSubscription = reaction(
		// 	() => this.league,
		// 	() => this.checkStartRound(),
		// 	{fireImmediately: true}
		// );

		// this.subscriptions$.push(leagueSubscription);
	}

	onChange(param: IParams): void {
		this._leagueId = param.leagueId;
	}

	@action
	private checkStartRound() {
		const races = this._racesStore.getNextRacesAccordingDate(this.league?.createdAt);
		const firstRace = first(races);
		if (firstRace) {
			this._rankingsStore.selectedRace = firstRace.feedId;
		}
	}
}
