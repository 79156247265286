import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGeoBlockStore} from "data/stores/geo_block/geo_block.store";

export interface IGeoBlockController extends ViewController {
	get isBlocked(): boolean;

	get i18n(): ILocalizationStore;
}

@injectable()
export class GeoBlockController implements IGeoBlockController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GeoBlockStore) private _geoBlockStore: IGeoBlockStore
	) {
		makeAutoObservable(this);
	}

	get isBlocked(): boolean {
		return this._geoBlockStore.isBlockedByCountry;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._geoBlockStore.ping();
	}

	onChange(param: void): void {
		return;
	}
}
