import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IHorse} from "data/types/race";
import type {IPlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import type {IHorseToTeamPayload} from "data/types/team";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRacesStore} from "data/stores/races/races.store";
import {HorseStatus} from "data/enums";

interface IParams {
	horseId: number;
}

export interface IHorseInfoActionsController extends ViewController<IParams> {
	addHorse: () => void;
	removeHorse: () => void;
	makeCaptain: () => void;

	get isActionsUnavailable(): boolean;

	get isAddAvailable(): boolean;

	get isRemoveAvailable(): boolean;

	get isSetCaptainAvailable(): boolean;

	get isCaptain(): boolean;

	get isTeamFull(): boolean;
}

@injectable()
export class HorseInfoActionsController implements IHorseInfoActionsController {
	@observable private _horseId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayerPoolStore) private _playerPoolStore: IPlayerPoolStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isTeamFull(): boolean {
		return this._teamStore.isTeamHorseFull;
	}

	get isActionsUnavailable(): boolean {
		return !this.horse || this.isLocked;
	}

	get isAddAvailable(): boolean {
		if (this.horse?.status === HorseStatus.NonRunner) {
			return false;
		}

		return !this._teamStore.getIsHorseInTeam(this._horseId);
	}

	get isRemoveAvailable(): boolean {
		return this._teamStore.getIsHorseInTeam(this._horseId);
	}

	get isSetCaptainAvailable(): boolean {
		if (!this.isRemoveAvailable || this._horseId === this._teamStore.donkeyInsurance) {
			return false;
		}
		return this.isTeamFull && this._teamStore.winningDistance !== undefined;
	}

	get isCaptain(): boolean {
		return this._horseId === this.team.captain;
	}

	private get horseTeamEntity(): IHorseToTeamPayload {
		return {
			horseId: this.horse?.id,
			position: this._playerPoolStore.selectedPosition,
		};
	}

	private get isLocked(): boolean {
		return this._racesStore.isRaceLocked;
	}

	private get team() {
		return this._teamStore.team;
	}

	private get horse(): IHorse | undefined {
		return this._horsesStore.getHorseById(this._horseId);
	}

	public addHorse = () => {
		if (!this.horse) {
			return;
		}
		this._teamStore.addHorseToTeam(this.horseTeamEntity);
		this._playerPoolStore.updateSelectedPosition(this._teamStore.team);
		this._playerPoolStore.closePlayerPool();
	};

	public makeCaptain = () => {
		if (!this.horse) {
			return;
		}
		this._teamStore.selectCaptain(this.horse.id);
	};

	public removeHorse = () => {
		if (!this.horse) {
			return;
		}
		this._teamStore.removeHorseById(this.horse.id);
		this._playerPoolStore.closePlayerPool();
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._horseId = param.horseId;
	}

	onChange(param: IParams): void {
		this._horseId = param.horseId;
	}
}
