import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IHorse} from "data/types/race";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {MINIMUM_BOOSTER_HORSE_COUNT} from "data/constants";

export interface IHorsesStore {
	get list(): IHorse[];

	get isLoading(): boolean;

	get isNoHorsesForBooster(): boolean;

	fetchHorses(raceId: number): Promise<void>;

	fetchHorsesPromise(raceId: number): Promise<IHorse[]>;

	getHorseById(id: number): IHorse | undefined;

	clearHorses(): void;
}

@injectable()
export class HorsesStore implements IHorsesStore {
	@observable private _isLoading: boolean = false;
	@observable private _list: IHorse[] = [];

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get list(): IHorse[] {
		return this._list;
	}

	get isNoHorsesForBooster(): boolean {
		return this.list.length < MINIMUM_BOOSTER_HORSE_COUNT;
	}

	public getHorseById(id: number): IHorse | undefined {
		return this.list.find((e) => e.id === id);
	}

	@action
	public async fetchHorses(raceId: number): Promise<void> {
		try {
			this._isLoading = true;
			const {data} = await this._jsonProvider.horses(raceId);

			if (!data || !Array.isArray(data)) {
				throw new Error("Error while loading horses");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public async fetchHorsesPromise(raceId: number): Promise<IHorse[]> {
		try {
			const {data} = await this._jsonProvider.horses(raceId);

			if (!data || !Array.isArray(data)) {
				throw new Error("Error while loading horses");
			}
			return Promise.resolve(data);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public clearHorses() {
		this._list = [];
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
