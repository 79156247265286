import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague} from "data/stores/leagues/leagues.store";
import {copyToClipboard} from "data/utils";

interface IProps {
	league: ILeague | null;
}

export interface ILeagueInviteFormController extends ViewController<IProps> {
	copyCode: () => void;

	get i18n(): ILocalizationStore;

	get isCodeCopied(): boolean;
}

@injectable()
export class LeagueInviteFormController implements ILeagueInviteFormController {
	@observable private _league: ILeague | null = null;
	@observable private _isCodeCopied = false;
	@observable private _copyCodeTextTimeout?: ReturnType<typeof setTimeout>;

	constructor(@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	get isCodeCopied() {
		return this._isCodeCopied;
	}

	copyCode = () => {
		const code = this._league?.code ?? "";

		copyToClipboard(code).then(this.onCopyCodeCallback).catch(this.onCopyCodeCallback);
	};

	@action init(param: IProps) {
		this._league = param.league;
	}

	@action onChange(param: IProps) {
		this._league = param.league;
	}

	dispose() {
		if (this._copyCodeTextTimeout) clearTimeout(this._copyCodeTextTimeout);
	}

	@action private onCopyCodeState = () => {
		this._isCodeCopied = false;
	};

	@action private onCopyCodeCallback = (isSuccess: boolean) => {
		this._isCodeCopied = isSuccess;

		if (isSuccess) {
			this._copyCodeTextTimeout = setTimeout(this.onCopyCodeState, 5000);
		}
	};
}
