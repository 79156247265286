import {inject, injectable} from "inversify";
import type {
	IUserTeamFetchPayload,
	IHorsePoints,
	IDonkeyInsurancePayload,
	ISaveTeamPayload,
	ITeam,
} from "data/types/team";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IDonkeyInsuranceCountResponse {
	donkeyInsuranceCount: number;
}

type THorsePointsResponse = IApiResponse<{horsePoints: IHorsePoints}>;
type TTeamResponse = IApiResponse<{team: ITeam}>;
type TDonkeyInsuranceCountResponse = IDonkeyInsuranceCountResponse;

export interface ITeamApiProvider {
	fetchUserTeam: (payload: IUserTeamFetchPayload) => Promise<AxiosResponse<TTeamResponse>>;
	fetchTeam: (raceId: number) => Promise<AxiosResponse<TTeamResponse>>;
	saveTeam: (payload: ISaveTeamPayload) => Promise<AxiosResponse<TTeamResponse>>;
	applyDonkeyInsurance: (
		payload: IDonkeyInsurancePayload
	) => Promise<AxiosResponse<TTeamResponse>>;
	fetchDonkeyInsurance: () => Promise<AxiosResponse<TDonkeyInsuranceCountResponse>>;
	viewTeamChanged: (raceId: number) => Promise<AxiosResponse<TTeamResponse>>;
	fetchHorsePoints: (horseFeedId: number) => Promise<AxiosResponse<THorsePointsResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetchUserTeam = (payload: IUserTeamFetchPayload) =>
		this._http.get<TTeamResponse>(`fantasy/team/${payload.raceId}/${payload.userId}`);
	fetchTeam = (raceId: number) => this._http.get<TTeamResponse>(`fantasy/team/${raceId}`);
	saveTeam = (payload: ISaveTeamPayload) =>
		this._http.post<TTeamResponse>(`fantasy/team`, payload);
	applyDonkeyInsurance = (payload: IDonkeyInsurancePayload) => {
		const url = payload.horseId
			? `fantasy/team/donkey/${payload.raceId}/${payload.horseId}`
			: `fantasy/team/donkey/${payload.raceId}`;
		return this._http.post<TTeamResponse>(url);
	};
	fetchDonkeyInsurance = () => {
		return this._http.get<TDonkeyInsuranceCountResponse>("fantasy/team/donkey/count");
	};
	viewTeamChanged = (raceId: number) => {
		return this._http.post<TTeamResponse>(`fantasy/team/auto/${raceId}`);
	};

	fetchHorsePoints = (horseFeedId: number) => {
		return this._http.get<THorsePointsResponse>(`fantasy/team/horse/points/${horseFeedId}`);
	};
}
