import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {sortHorses} from "data/utils/player_pool";
import type {IHorse, IRace} from "data/types/race";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IPlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParams {
	isMobile: boolean;
}

export interface IPlayerPoolController extends ViewController<IParams> {
	togglePlayerPool: () => void;

	get i18n(): ILocalizationStore;

	get race(): IRace | undefined;

	get horses(): IHorse[];

	get isOpen(): boolean;

	get isComplete(): boolean;

	get isLoading(): boolean;

	get openClass(): string;

	get minHeight(): number;

	get isCMSRace(): boolean;
}

@injectable()
export class PlayerPoolController implements IPlayerPoolController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _isMobile: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.PlayerPoolStore) private _playerPoolStore: IPlayerPoolStore
	) {
		makeAutoObservable(this);
	}

	get minHeight(): number {
		return this.horses.length * 64;
	}

	get isOpen(): boolean {
		return this._playerPoolStore.isOpen;
	}

	get openClass(): string {
		return this.isOpen ? "open" : "";
	}

	get horses(): IHorse[] {
		return sortHorses(
			this._horsesStore.list,
			this.filters.status,
			this.filters.stats,
			this.isComplete
		);
	}

	get isComplete(): boolean {
		return this._racesStore.isRaceComplete;
	}

	get isLoading(): boolean {
		return this._horsesStore.isLoading;
	}

	get isCMSRace(): boolean {
		return this._racesStore.isCMSRace;
	}

	get race(): IRace | undefined {
		return this._racesStore.selectedRace;
	}

	private get filters() {
		return this._playerPoolStore.filters;
	}

	public togglePlayerPool = () => {
		this._playerPoolStore.togglePlayerPool();
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose);
	}

	init(param: IParams): void {
		this._isMobile = param.isMobile;

		this.checkOpenClass();
		const subscription = reaction(
			() => this._isMobile,
			() => this.checkOpenClass()
		);

		this.subscriptions$.push(subscription);
	}

	onChange(param: IParams): void {
		this._isMobile = param.isMobile;
	}

	@action
	private checkOpenClass() {
		if (this._isMobile) {
			this._playerPoolStore.closePlayerPool();
		}
	}
}
