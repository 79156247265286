import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {HorseStatus} from "data/enums";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IHorse} from "data/types/race";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParams {
	horseId: number;
}

export interface IPlayerPoolActionsCompleteIconController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get finishedPosition(): number | null | undefined;

	get className(): string;

	get isFirstPlace(): boolean;

	get isSecondPlace(): boolean;

	get isThirdPlace(): boolean;

	get isNotFinished(): boolean;

	get isNotWinner(): boolean;

	get isInjured(): boolean;

	get isNonScored(): boolean;
}

@injectable()
export class PlayerPoolActionsCompleteIconController
	implements IPlayerPoolActionsCompleteIconController
{
	@observable private _horseId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore
	) {
		makeAutoObservable(this);
	}

	get isFirstPlace(): boolean {
		return this.finishedPosition === 1;
	}

	get isSecondPlace(): boolean {
		return this.finishedPosition === 2;
	}

	get isThirdPlace(): boolean {
		return this.finishedPosition === 3;
	}

	get isNotFinished(): boolean {
		return !this.finishedPosition;
	}

	get isNotWinner(): boolean {
		return Boolean(this.finishedPosition && this.finishedPosition > 3);
	}

	get isInjured(): boolean {
		return this.horse?.status === HorseStatus.NonRunner;
	}

	get className(): string {
		if (this.isFirstPlace) {
			return "first";
		}
		if (this.isSecondPlace) {
			return "second";
		}
		if (this.isThirdPlace) {
			return "third";
		}
		if (this.isNotWinner) {
			return "non-winner";
		}
		if (this.isNotFinished) {
			return "dnf";
		}
		return "";
	}

	get finishedPosition(): number | null | undefined {
		return this.horse?.finishedPosition;
	}

	private get horse(): IHorse | undefined {
		return this._horsesStore.getHorseById(this._horseId);
	}

	get isNonScored(): boolean {
		return this._horsesStore.list.every((e) => !e.finishedPosition);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._horseId = param.horseId;
	}

	onChange(param: IParams): void {
		this._horseId = param.horseId;
	}
}
