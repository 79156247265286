import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import React from "react";
import type {ILoginFormElement} from "data/types/forms";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import type {IUserStore} from "data/stores/user/user.store";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";

export interface IFormLoginController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<ILoginFormElement>) => void;
	handleFormChange: () => void;
	openRegister: () => void;

	get i18n(): ILocalizationStore;

	get isLoading(): boolean;

	get isPasswordChanged(): boolean;

	get errorMsg(): string | null;
}

@injectable()
export class FormLoginController implements IFormLoginController {
	@observable private _errorMsg: string | null = null;
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isPasswordChanged(): boolean {
		return this._userStore.isPasswordChanged;
	}

	get errorMsg(): string | null {
		return this._errorMsg;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	@action
	public handleFormChange = () => {
		if (this._errorMsg) this._errorMsg = null;
		if (this._isLoading) this._isLoading = false;
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<ILoginFormElement>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		const payload: ILoginPayload = {
			email: email.value,
			password: password.value,
		};

		this._isLoading = true;
		this._userStore
			.login(payload)
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	public openRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTER);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	private onSuccess = () => {
		this._modalsStore.hideModal();
	};

	@action
	private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
	};
}
