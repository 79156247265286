import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRace} from "data/types/race";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorseRacePoints} from "data/types/team";
import type {IHorsePointsStore} from "data/stores/horse_points/horse_points.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParams {
	raceId: number;
}

interface IRaceInfoModalData {
	race: IRace | undefined;
	scoring: IHorseRacePoints | undefined;
}

export interface IHorseInfoSingleRaceController extends ViewController<IParams> {
	togglePoints: () => void;

	prepareScoringByType: (type: string, points: number) => string;

	get i18n(): ILocalizationStore;

	get raceInfo(): IRaceInfoModalData;

	get isShowPoints(): boolean;

	get totalPoints(): number;
}

@injectable()
export class HorseInfoSingleRaceController implements IHorseInfoSingleRaceController {
	@observable private _raceId: number = Number.MAX_SAFE_INTEGER;
	@observable private _isShowPoints: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsePointsStore) private _horsePointsStore: IHorsePointsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	public prepareScoringByType = (type: string, points: number) => {
		if (type === "captain") {
			return "";
		}

		return this.i18n.t("horse_info.race.pts_variable", "{{X}} pts", {X: points});
	};

	get isShowPoints(): boolean {
		return this._isShowPoints;
	}

	get totalPoints(): number {
		if (!this.scoring) {
			return 0;
		}

		return this.scoring.scores?.reduce((acc, val) => acc + (val.pts || 0), 0) || 0;
	}

	get raceInfo(): IRaceInfoModalData {
		return {
			race: this.race,
			scoring: this.scoring,
		};
	}

	private get scoring(): IHorseRacePoints | undefined {
		return this._horsePointsStore.getHorsePointsByRace(this._raceId);
	}

	private get race(): IRace | undefined {
		return this._racesStore.getRaceById(this._raceId);
	}

	@action
	public togglePoints = () => {
		this._isShowPoints = !this._isShowPoints;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._raceId = param.raceId;
	}

	onChange(param: IParams): void {
		this._raceId = param.raceId;
	}
}
