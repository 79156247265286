import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import React from "react";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IHorse} from "data/types/race";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {makeAutoObservable} from "mobx";
import {TEAM_HORSE_POSITIONS} from "data/constants";

export interface IModalCaptainPickController extends ViewController {
	close: () => void;

	pickCaptain: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	get i18n(): ILocalizationStore;

	get horses(): IHorse[];

	get isOpen(): boolean;
}

@injectable()
export class ModalCaptainPickController implements IModalCaptainPickController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.CAPTAIN_PICK;
	}

	get horses(): IHorse[] {
		return this.horseIds
			.map((horseId) => this._horsesStore.getHorseById(horseId))
			.filter((horse) => horse) as IHorse[];
	}

	private get horseIds(): number[] {
		return TEAM_HORSE_POSITIONS.map((position) => this._teamStore.team[position] || -1);
	}

	public pickCaptain = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		const id = Number(event.currentTarget.dataset.id);
		if (!id) {
			return;
		}

		this._teamStore.selectCaptain(id);
		this.close();
	};

	public close = () => {
		this._modalsStore.hideModal();
	};
}
