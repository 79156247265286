import styled from "@emotion/styled";
import {Button, Container} from "@mui/material";
import bg_desktop from "assets/img/landing/background_desktop.svg";
import bg_mobile from "assets/img/landing/background_mobile.svg";

export const PageWrapper = styled.div`
	width: 100%;
	min-height: calc(100vh - 120px - 76px);
	background: url(${bg_desktop});
	background-size: cover;
	position: relative;
`;

export const PageTitle = styled.h1`
	color: #ffffff;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 33.6px */
	text-transform: capitalize;
	margin-bottom: 20px;
`;

export const PageLayout = styled(Container)`
	padding: 40px 0;
	//min-height: calc(100vh - 120px - 76px);
	min-height: 100%;

	@media screen and (max-width: 640px) {
		padding: 20px 16px;
		@media screen and (max-width: 640px) {
			background: url(${bg_mobile});
			min-height: calc(100vh - 120px - 76px);
			background-size: cover;
		}
	}
`;

export const TwoSideLayout = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 24px;
	padding-bottom: 40px;

	@media screen and (max-width: 840px) {
		flex-direction: column;
		gap: 12px;
	}

	@media screen and (max-width: 640px) {
		padding: 0;
	}
`;
export const TwoSideLayoutNoPadding = styled(TwoSideLayout)`
	padding-bottom: 0;
`;

export const LayoutMainSide = styled.div`
	width: 100%;
`;

export const LayoutMainSideContent = styled.div`
	width: 100%;
	//height: 100%;
	background: #ffffff;
	padding: 40px 16px;
	border-radius: 8px;
`;

export const LayoutSideBar = styled.div`
	max-width: 384px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;

	@media screen and (max-width: 1000px) {
		max-width: 300px;
	}

	@media screen and (max-width: 840px) {
		max-width: unset;
	}
`;
export const LayoutSideBarNone = styled(LayoutSideBar)`
	@media screen and (max-width: 840px) {
		display: none;
	}
`;

export const HorseBlockImage = styled.img`
	max-width: 112px;
	min-height: 114px;
	width: 100%;
	height: auto;
	z-index: 4;
	position: relative;

	@media screen and (max-width: 1000px) {
		max-width: 100px;
		height: auto;
	}

	@media screen and (max-width: 880px) {
		max-width: 80px;
		height: auto;
	}

	@media screen and (max-width: 840px) {
		max-width: 100px;
		height: auto;
	}

	&.absolute {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 3;
		min-height: unset;

		@media screen and (max-width: 1000px) {
			max-width: 100px;
			height: auto;
			top: 50%;
			transform: translateY(-50%);
		}

		@media screen and (max-width: 880px) {
			max-width: 80px;
			height: auto;
		}

		@media screen and (max-width: 840px) {
			max-width: 100px;
			height: auto;
		}
	}
`;

export const HorseEmptyLabel = styled.p`
	color: #ffffff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 22.4px */
	padding: 7px 10px;
	max-width: 120px;
	width: 100%;
	background: #335948;
	border-radius: 8px;

	&.active {
		background: #ffffff;
		color: #335948;
	}

	@media screen and (max-width: 1000px) {
		max-width: 100px;
		font-size: 14px;
	}

	@media screen and (max-width: 880px) {
		max-width: 80px;
		font-size: 12px;
	}

	@media screen and (max-width: 840px) {
		max-width: 76px;
		padding: 3px 6px;
		font-size: 12px;
	}
`;

export const BaseAction = styled(Button)`
	min-width: 24px;
	min-height: 24px;
	width: 24px;
	height: 24px;
	padding: 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
`;

export const Notification = styled.div`
	border-radius: 4px;
	background: var(--primary-80);
	display: flex;
	max-width: 400px;
	width: 100%;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;

	color: #ffffff;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 19.6px */

	&.fixed-notification {
		@media screen and (max-width: 640px) {
			width: 100%;
			max-width: unset;
			border-radius: 0;
		}
	}
`;

export const LeaguesSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 12px;
`;

export const LeaguesSectionTitle = styled.h4`
	color: #ffffff;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
`;

export const LeaguesNotFound = styled.div`
	width: 100%;
	background: #ffffff;
	text-align: center;
	border-radius: 8px;
	padding: 12px;
	font-weight: bold;

	font-size: 16px;
	font-style: normal;
	line-height: 140%; /* 19.6px */
	color: var(--primaryColorDark);
`;

export const LeaguesSectionTitleEmpty = styled.div`
	height: 29px;
	width: 100%;

	@media screen and (max-width: 840px) {
		display: none;
	}
`;

export const LeagueSectionText = styled.p`
	color: var(--primaryColorDark);
	font-size: 16px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 22.4px */

	b {
		font-weight: bold;
	}
`;

export const ActionsWrapper = styled.div`
	background: #ffffff;
	padding: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	width: 100%;

	h4 {
		color: var(--primaryColorDark);
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	p {
		color: var(--primaryColorDark);
		font-size: 16px;
		font-style: normal;
		font-weight: 450;
		line-height: 140%; /* 22.4px */
	}

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 12px;
		width: 100%;

		a {
			width: 100%;
		}
	}
`;

export const LeagueEntityInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;

	h4 {
		color: var(--primaryColorDark);
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		margin-top: 6px;
	}

	p {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 2px;

		a {
			position: relative;
			z-index: 1;
		}

		span,
		b,
		a {
			display: flex;
			align-items: center;
			color: var(--primaryColorDark);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 19.6px */
		}

		b {
			font-weight: bold;
		}

		> div {
			height: 16px;
			margin: 0 6px;
			background: var(--secondary-80);
			width: 2px;
		}
	}

	&.celebrity p > div {
		background: var(--primaryColorDark);
	}
`;

export const LeaguePrivacyPill = styled.div`
	padding: 4px 8px;
	font-size: 12px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%;
	border-radius: 4px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;

	background: var(--secondary-60);
	color: var(--primaryColorDark);

	&.public {
		background: var(--secondary-60);
		color: var(--primaryColorDark);
	}

	&.private {
		color: #ffffff;
		background: var(--primary-80);
	}
`;

export const LeaguePageMainBlock = styled.div`
	background: #ffffff;
	width: 100%;
	border-radius: 0 8px 8px 8px;
	padding: 20px;
`;

export const TabsWrapper = styled.div`
	a {
		min-width: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid #80988c;
		background: #4d6d5f;
		color: #ffffff;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%; /* 19.6px */
		text-transform: capitalize;

		&.active {
			color: var(--primaryColorDark);
			background: #ffffff;
		}

		&:first-of-type {
			border-radius: 8px 0 0 0;
		}

		&:last-of-type {
			border-right: none;
			border-radius: 0 8px 0 0;
		}
	}

	.MuiTabs-indicator {
		display: none;
	}

	@media screen and (max-width: 640px) {
		padding: 0;

		a {
			min-width: unset;
		}
	}
`;

export const AdWrapper = styled.div`
	padding-top: 40px;

	@media screen and (max-width: 640px) {
		padding-top: 16px;
	}
`;

export const AdWrapperSidebar = styled.div`
	padding-top: 10px;
`;
