import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import type {IRace} from "data/types/race";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IEvent} from "data/stores/events/events.store";
import {RaceStatus} from "data/enums";
import {DateTime} from "luxon";

interface IParams {
	event: IEvent;
}

export interface IRaceScheduleStateController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get race(): IRace | undefined;

	get isSchedule(): boolean;

	get isLive(): boolean;

	get isComplete(): boolean;

	get days(): number;
}

@injectable()
export class RaceScheduleStateController implements IRaceScheduleStateController {
	@observable private _event: IEvent | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get days() {
		const startDate = this.race?.date || this._event?.date;
		if (!startDate) {
			return 0;
		}

		const days = DateTime.fromISO(startDate).diffNow("days").get("days") || 0;

		if (days < 0) {
			return 0;
		}

		return Math.round(days);
	}

	get isComplete(): boolean {
		return this.race?.status === RaceStatus.Complete;
	}

	get isLive(): boolean {
		return this.race?.status === RaceStatus.Playing;
	}

	get isSchedule(): boolean {
		if (!this.race) {
			return true;
		}
		return this.race?.status === RaceStatus.Scheduled;
	}

	get race(): IRace | undefined {
		return this._racesStore.getRaceById(this.raceId || -1);
	}

	private get raceId() {
		return this._event?.race;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._event = param.event;
	}

	onChange(param: IParams): void {
		this._event = param.event;
	}
}
