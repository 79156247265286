import React from "react";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {AxiosError} from "axios";
import {ModalType} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IApiResponse} from "data/services/http";
import {debounce} from "lodash";

interface IProps {
	code?: string;
}

export interface IJoinLeaguesPageController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class JoinLeaguesPageController implements IJoinLeaguesPageController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const search = event.currentTarget.value || "";

		this.onSearchChange(search);
	};

	init(param: IProps) {
		return;
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private onSearchChange = debounce((search: string) => {
		this._leaguesStore.fetchLeaguesForJoin({search}).catch(this.onError);
	}, 500);
}
