import React, {Fragment, PropsWithChildren} from "react";
import {PagePreloader} from "views/components/preloader";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import type {ISessionController} from "views/components/session/session.controller";
import {ILiveScoreController} from "views/controllers/live_score/live_score.controller";
import {ChecksumType} from "data/enums";

export const Session: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isSessionChecked} = useViewController<ISessionController>(Bindings.SessionController);
	useViewController<ILiveScoreController>(Bindings.LiveScoreController, {
		type: ChecksumType.Common,
	});

	return isSessionChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
