/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "700",
					fontSize: "16px",
					borderRadius: "8px",
					padding: "9px 22px",
					boxShadow: "none",
					minHeight: "48px",
					textTransform: "capitalize",
					":disabled": {
						cursor: "default",
					},
				},
				containedPrimary: {
					color: "#FFFFFF",
					background: "var(--primaryColor)",
					border: "1px solid var(--primaryColor)",
					":hover": {
						boxShadow: "none",
						background: "var(--primaryColorHover)",
						borderColor: "var(--primaryColorHover)",
					},
					":disabled": {
						borderColor: "var(--primary-30)",
						background: "var(--primary-30)",
						color: "#FFFFFF",
					},
				},
				outlinedPrimary: {
					borderColor: "var(--primaryColor)",
					background: "#FFFFFF",
					color: "var(--primaryColor)",
					":hover": {
						borderColor: "var(--primaryColorHover)",
						background: "var(--primaryColorHover)",
						color: "#FFFFFF",
					},
					":disabled": {
						borderColor: "var(--primaryColor)",
						background: "var(--primaryColor)",
						opacity: "var(--disabledOpacity)",
						color: "#FFFFFF",
					},
				},
				outlinedSecondary: {
					background: "transparent",
					borderColor: "#FFFFFF",
					color: "#FFFFFF",
					":hover": {
						borderColor: "#FFFFFF",
						background: "#FFFFFF",
						color: "var(--primaryColor)",
					},
					":disabled": {
						borderColor: "#FFFFFF",
						background: "transparent",
						color: "#FFFFFF",
					},
				},
				containedError: {
					background: "var(--invalidColor)",
					borderColor: "var(--invalidColor)",
					color: "#FFFFFF",
					":hover": {
						borderColor: "var(--invalidColorHover)",
						background: "var(--invalidColorHover)",
						color: "#FFFFFF",
						boxShadow: "none",
					},
					":disabled": {
						background: "var(--invalidColor)",
						borderColor: "var(--invalidColor)",
						color: "#FFFFFF",
						opacity: "var(--disabledOpacity)",
					},
				},
				outlinedError: {
					background: "transparent",
					borderColor: "var(--invalidColor)",
					color: "var(--invalidColor)",
					":hover": {
						borderColor: "var(--invalidColor)",
						background: "var(--invalidColor)",
						color: "#FFFFFF",
					},
					":disabled": {
						background: "transparent",
						borderColor: "var(--invalidColor)",
						color: "var(--invalidColor)",
						opacity: "var(--disabledOpacity)",
					},
				},
			},
			defaultProps: {
				color: "primary",
				variant: "contained",
				fullWidth: true,
			},
		},
	},
};
