import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {HorseStatus, ModalType} from "data/enums";
import type {IHorse} from "data/types/race";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRacesStore} from "data/stores/races/races.store";

interface IParams {
	horseId: number;
}

export interface IPlayerPoolRowController extends ViewController<IParams> {
	openHorseModal: () => void;

	get horse(): IHorse | undefined;

	get className(): string;
}

@injectable()
export class PlayerPoolRowController implements IPlayerPoolRowController {
	@observable private _horseId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get className(): string {
		let className = "";
		if (this.horse?.status === HorseStatus.NonRunner) {
			className += "injured ";
		}
		if (this._teamStore.getIsHorseInTeam(this.horse?.id || -1)) {
			className += "picked ";
		}
		if (this._racesStore.isRaceComplete) {
			className += "complete ";
		}
		return className;
	}

	get horse() {
		return this._horsesStore.getHorseById(this._horseId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._horseId = param.horseId;
	}

	onChange(param: IParams): void {
		this._horseId = param.horseId;
	}

	public openHorseModal = () => {
		if (!this._horseId) {
			return;
		}
		this._modalsStore.showModal(ModalType.HORSE_INFO, {horseId: this._horseId});
	};
}
