import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {SelectChangeEvent} from "@mui/material";
import type {IFederation} from "data/types/race";
import type {ISecondOptStore} from "data/stores/second_opt/second_opt.store";

export interface IModalSecondOptController extends ViewController {
	close: () => void;
	confirm: () => void;
	handleSelectChange: (event: SelectChangeEvent<unknown>) => void;
	getFederationNameByName: (name: string) => string;

	get isOpen(): boolean;

	get value(): string[];

	get federations(): IFederation[];

	get isAllSelected(): boolean;

	get isSubmitDisabled(): boolean;
}

@injectable()
export class ModalSecondOptController implements IModalSecondOptController {
	constructor(
		@inject(Bindings.SecondOptStore) private readonly _secondOptStore: ISecondOptStore
	) {
		makeAutoObservable(this);
	}

	get federations(): IFederation[] {
		return this._secondOptStore.federations;
	}

	get value() {
		return this._secondOptStore.selectedFederations.map((e) => e.name);
	}

	get isOpen(): boolean {
		return this._secondOptStore.isModalOpen;
	}

	get isAllSelected(): boolean {
		const selectedLength = this._secondOptStore.selectedFederations.length;
		const totalLength = this._secondOptStore.federations.length;
		if (!selectedLength || !totalLength) {
			return false;
		}
		return selectedLength === totalLength;
	}

	get isSubmitDisabled() {
		return this._secondOptStore.selectedFederations.length < 1;
	}

	public getFederationNameByName = (name: string) => {
		const federation = this.federations.find((e) => e.name === name);
		return federation?.name || "";
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public close = () => {
		this._secondOptStore.selectedFederations = [];
		this._secondOptStore.closeModal();
	};

	public confirm = () => {
		this._secondOptStore.closeModal();
	};

	@action
	public handleSelectChange = (event: SelectChangeEvent<unknown>): void => {
		const value = event.target.value as string[];
		if (value.includes("all")) {
			this.checkAllSelected();
			return;
		}

		if (!value) {
			this._secondOptStore.selectedFederations = [];
		}
		this._secondOptStore.selectedFederations = value
			.map((e) => this.federations.find((federation) => federation.name === e))
			.filter(Boolean) as IFederation[];
	};

	private checkAllSelected() {
		if (this.isAllSelected) {
			this._secondOptStore.selectedFederations = [];
		} else {
			this._secondOptStore.selectedFederations = this._secondOptStore.federations;
		}
	}
}
