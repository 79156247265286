import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import {RaceStatus, RankingsSortValue, SortOrder} from "data/enums";
import {last} from "lodash";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRanking} from "data/providers/api/rankings.api.provider";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IRankingSortFilter} from "data/types/filter";
import type {IUserStore} from "data/stores/user/user.store";

export interface ILeaderboardController extends ViewController {
	loadMore: () => void;
	getIsUserRow: (userId: number | null) => string;
	getIsOrderActiveClass: (sortValue: RankingsSortValue, sortOrder: SortOrder) => string;
	toggleOverallPoints: () => void;
	toggleRacePoints: () => void;

	get i18n(): ILocalizationStore;

	get isLoading(): boolean;

	get hasNextPage(): boolean;

	get rankings(): IRanking[];

	get rankingsUser(): IRanking | null;

	get isUserInRow(): boolean;

	get isOverall(): boolean;

	get isComplete(): boolean;

	get selectedRaceId(): number;

	get isScoringStarted(): boolean;
}

@injectable()
export class LeaderboardController implements ILeaderboardController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOverall(): boolean {
		const race = this._rankingsStore.selectedRace;
		return !race || race === "overall";
	}

	get isLoading(): boolean {
		return this._rankingsStore.isLoading;
	}

	get selectedRaceId(): number {
		const raceFeedId = this._rankingsStore.selectedRace;

		if (this.isOverall || !raceFeedId) {
			return last(this._racesStore.completedList)?.id || -1;
		}

		const race = this._racesStore.getRaceByFeedId(Number(raceFeedId));
		return race?.id || -1;
	}

	get isUserInRow(): boolean {
		return this.rankings.some((e) => e.userId === this._userStore.user?.id);
	}

	get isScoringStarted(): boolean {
		return this._racesStore.list.some((race) => race.status === RaceStatus.Complete);
	}

	get isComplete(): boolean {
		const race = this._rankingsStore.selectedRace;

		if (this.isOverall) {
			return true;
		}

		const raceEntity = this._racesStore.getRaceByFeedId(race as number);
		return raceEntity?.status === RaceStatus.Complete;
	}

	get hasNextPage(): boolean {
		return this._rankingsStore.hasNextPage;
	}

	get rankings(): IRanking[] {
		return this._rankingsStore.rankings;
	}

	get rankingsUser(): IRanking | null {
		return this._rankingsStore.rankingUser;
	}

	private get sortFilters(): IRankingSortFilter {
		return this._rankingsStore.sortFilters;
	}

	public getIsOrderActiveClass = (sortValue: RankingsSortValue, sortOrder: SortOrder) => {
		const isSortValue = sortValue === this._rankingsStore.sortFilters.value;
		const isSortOrder = sortOrder === this._rankingsStore.sortFilters.order;

		return isSortOrder && isSortValue ? "active" : "";
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public toggleOverallPoints = () => {
		const isAsc = this.sortFilters.order === SortOrder.ASC;
		if (this.sortFilters.value !== RankingsSortValue.OverallPoints) {
			this._rankingsStore.sortFilters = {
				value: RankingsSortValue.OverallPoints,
				order: SortOrder.DESC,
			};
		} else {
			this._rankingsStore.sortFilters = {
				value: RankingsSortValue.OverallPoints,
				order: isAsc ? SortOrder.DESC : SortOrder.ASC,
			};
		}
	};

	public toggleRacePoints = () => {
		const isAsc = this.sortFilters.order === SortOrder.ASC;
		if (this.sortFilters.value !== RankingsSortValue.RacePoints) {
			this._rankingsStore.sortFilters = {
				value: RankingsSortValue.RacePoints,
				order: SortOrder.DESC,
			};
		} else {
			this._rankingsStore.sortFilters = {
				value: RankingsSortValue.RacePoints,
				order: isAsc ? SortOrder.DESC : SortOrder.ASC,
			};
		}
	};

	public loadMore = () => {
		this._rankingsStore
			.fetchMoreRankings()
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error));
	};

	public getIsUserRow = (userId: number | null) => {
		return userId === this._userStore.user?.id ? "user" : "";
	};
}
