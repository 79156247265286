import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import React from "react";
import type {IInviteLeague} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IApiResponse} from "data/services/http";
import type {ILeagueInvitesStore} from "data/stores/leagues_invites/leagues_invites.store";
import {AxiosError} from "axios";

export interface IInvitedLeaguesController extends ViewController {
	joinLeague: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	cancelLeague: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	get i18n(): ILocalizationStore;

	get leagues(): IInviteLeague[];

	get isLoading(): boolean;

	get isJoining(): boolean;
}

@injectable()
export class InvitedLeaguesController implements IInvitedLeaguesController {
	@observable private _isLoading: boolean = false;
	@observable private _isJoining: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeagueInvitesStore) private _leagueInvitesStore: ILeagueInvitesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get leagues(): IInviteLeague[] {
		return this._leagueInvitesStore.list;
	}

	get isJoining(): boolean {
		return this._isJoining;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	@action
	public joinLeague = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		const leagueCode = String(event.currentTarget.dataset.code);
		if (!leagueCode) {
			return;
		}

		this._isJoining = true;
		this._leagueInvitesStore
			.joinInvitedLeague(leagueCode)
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error))
			.finally(() => {
				runInAction(() => {
					this._isJoining = false;
				});
			});
	};

	@action
	public cancelLeague = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		const leaguedId = Number(event.currentTarget.dataset.id);
		if (!leaguedId) {
			return;
		}

		this._isJoining = true;
		this._leagueInvitesStore
			.cancelInvitedLeague(leaguedId)
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error))
			.finally(() => {
				runInAction(() => {
					this._isJoining = false;
				});
			});
	};

	dispose(): void {
		return;
	}

	@action
	init(param: void): void {
		this._isLoading = true;

		this._leagueInvitesStore
			.fetchInvites()
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error))
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	}

	onChange(param: void): void {
		return;
	}
}
