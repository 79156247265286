import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {SyntheticEvent} from "react";
import * as React from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IUpdateUserPayload} from "data/providers/api/user.api.provider";

interface IMyAccountFormElement extends HTMLFormElement {
	firstName: HTMLInputElement;
	surName: HTMLInputElement;
	displayName: HTMLInputElement;
	email: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleNotificationsChange: () => void;
	handleChangeNotifications: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
	handleClearErrorOnChange: () => void;
	handleLogout: () => void;
	onPasswordChange: () => void;

	get i18n(): ILocalizationStore;

	get isFormDisabled(): boolean;

	get error(): Record<string, string> | null;

	get user(): IUser;

	get isUpdateDisabled(): boolean;

	get isNotificationsDisabled(): boolean;

	get isNotificationVisible(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isFormChanged = false;
	@observable private _isNotificationsEnabledLocal = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
		this._isNotificationsEnabledLocal = this.user.isNotificationsEnabled;
	}

	get isNotificationsDisabled(): boolean {
		return this._isNotificationsEnabledLocal === this.user.isNotificationsEnabled;
	}

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	@action
	public handleNotificationsChange = () => {
		this._requestState = RequestState.PENDING;

		// display name is required on BE
		const payload: IUpdateUserPayload = {
			isNotificationsEnabled: this._isNotificationsEnabledLocal,
			displayName: this.user.displayName,
		};
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isNotificationsEnabledLocal = this.user.isNotificationsEnabled;
				})
			)
			.catch(this.onError);
	};

	@action
	public handleChangeNotifications = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		this._isNotificationsEnabledLocal = checked;
	};

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {firstName, surName, displayName, email} = event.currentTarget;

		const validateList = [
			{field: email, error: "Please provide a valid email address", place: "email"},
			{field: displayName, error: "Please provide your display name", place: "username"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			firstName: firstName.value,
			surName: surName.value,
			displayName: displayName.value.replaceAll("@", ""),
			email: email.value,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore
			.logout()
			.then(() => {
				window.location.href = "/";
			})
			.catch(this.onError);
	};

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action
	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action
	public onPasswordChange = () => {
		setTimeout(() => {
			this._userStore.isPasswordChanged = false;
		}, 5000);
	};

	get isNotificationVisible(): boolean {
		return this._userStore.isPasswordChanged;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};
}
