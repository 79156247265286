import React, {ReactNode} from "react";
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {IMenuAccordionController} from "views/components/menu_accordion/menu_accordion.controller";
import {Bindings} from "data/constants/bindings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useLocation} from "react-router";

const Wrapper = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 0;

	> a {
		width: 100%;
		text-align: left;
	}

	svg {
		transition: 0.4s;
	}

	svg.open {
		transform: rotate(180deg);
	}
`;

const AccordionBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-height: 0;
	transition: 0.4s;
	overflow: hidden;

	a,
	button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid var(--common-borders);
		border-radius: 0;

		color: var(--primaryColorDark);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		padding: 14px 40px;

		&.active {
			background: var(--iconColorMedium);
		}
	}

	&.open {
		max-height: 150px;
	}
`;

interface IProps {
	children: ReactNode;
	link: ReactNode;
	pathName: string;
}

export const MenuAccordion: React.FC<IProps> = observer(({link, children, pathName}) => {
	const location = useLocation();
	const {isOpen, toggleAccordion} = useViewController<IMenuAccordionController>(
		Bindings.MenuAccordionController
	);
	const accordionClassName = isOpen ? "open" : "";

	const activeClass = location.pathname === pathName ? "active" : "";

	return (
		<React.Fragment>
			<Wrapper className={activeClass} onClick={toggleAccordion} variant="text">
				{link}
				<ArrowDropDownIcon className={accordionClassName} />
			</Wrapper>
			<AccordionBody className={accordionClassName}>{children}</AccordionBody>
		</React.Fragment>
	);
});
