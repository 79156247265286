import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {
	IDonkeyInsurancePayload,
	IHorseToTeamPayload,
	ISaveTeamPayload,
	ITeam,
} from "data/types/team";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";
import {TeamPosition} from "data/enums";
import {
	TEAM_HORSE_POSITIONS,
	TIE_BREAKER_RANGE_MAPPER,
	TIE_BREAKER_RANGE_MAPPER_REVERSE,
} from "data/constants";
import {debounce, get, isEqual} from "lodash";
import {ScrollHelper} from "data/utils/scroll_helper";

export interface ITeamStore {
	get isFetching(): boolean;

	get team(): ITeam | ISaveTeamPayload;

	get isTeamHorseFull(): boolean;

	get winningDistance(): undefined | number;

	get isTeamUpdated(): boolean;

	get donkeyInsurance(): number | null;

	get donkeyInsuranceCount(): number;

	fetchTeamForRace(raceId: number): Promise<void>;

	saveTeam(raceId: number): Promise<void>;

	addHorseToTeam(payload: IHorseToTeamPayload): void;

	removeHorseByPosition(position: TeamPosition): void;

	removeHorseById(horseId: number): void;

	getIsHorseInTeam(horseId: number): boolean;

	getIsHorseCaptain(horseId: number): boolean;

	updateWinningDistance(value: number | undefined): void;

	selectCaptain(horseId: number): void;

	clearTeam(): void;

	applyDonkeyInsurance(horseId: number | null): void;

	saveDonkeyInsurance(raceId: number): Promise<void>;

	fetchDonkeyInsuranceCount(): Promise<void>;

	viewTeamChanged(raceId: number): Promise<void>;
}

@injectable()
export class TeamStore implements ITeamStore {
	@observable private _donkeyInsuranceCount: number = 0;
	@observable private _donkeyInsurance: number | null = null;
	@observable private _isFetching: boolean = false;
	@observable private _savedTeam: ITeam | undefined;
	@observable private _team: ITeam | ISaveTeamPayload = {
		captain: null,
		[TeamPosition.FirstPlace]: null,
		[TeamPosition.SecondPlace]: null,
		[TeamPosition.ThirdPlace]: null,
		winningDistance: null,
	};

	constructor(@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider) {
		makeAutoObservable(this);
	}

	get donkeyInsuranceCount(): number {
		return this._donkeyInsuranceCount;
	}

	get donkeyInsurance(): number | null {
		return this._donkeyInsurance;
	}

	get isTeamHorseFull(): boolean {
		return TEAM_HORSE_POSITIONS.every((position) => Boolean(this.team[position]));
	}

	get team(): ITeam | ISaveTeamPayload {
		return this._team;
	}

	get isFetching(): boolean {
		return this._isFetching;
	}

	get winningDistance(): number | undefined {
		const val = get(
			TIE_BREAKER_RANGE_MAPPER_REVERSE,
			String(this.team.winningDistance)
		) as string;
		const valToNumber = val || this.team.winningDistance;
		const numberVal = valToNumber ? Number(valToNumber) : null;
		return numberVal === null ? undefined : numberVal;
	}

	get isTeamUpdated(): boolean {
		if (!this._savedTeam) {
			return this.isLocalTeamUpdated;
		}

		if (this._donkeyInsurance !== this._savedTeam.donkeyInsurance) {
			return true;
		}

		return !isEqual(this._team, this._savedTeam);
	}

	private get isLocalTeamUpdated(): boolean {
		return Object.values(this.team).some((value) => Boolean(value));
	}

	private get horseIds(): number[] {
		return TEAM_HORSE_POSITIONS.map((position) => this.team[position] || -1).filter(Boolean);
	}

	private get teamForSave(): ISaveTeamPayload {
		return {
			race: this.team.race,
			captain: this.team.captain,
			[TeamPosition.FirstPlace]: this.team[TeamPosition.FirstPlace],
			[TeamPosition.SecondPlace]: this.team[TeamPosition.SecondPlace],
			[TeamPosition.ThirdPlace]: this.team[TeamPosition.ThirdPlace],
			winningDistance: this.team.winningDistance,
		};
	}

	@action
	public applyDonkeyInsurance(horseId: number | null): void {
		this._donkeyInsurance = horseId;
	}

	@action
	public async saveDonkeyInsurance(raceId: number): Promise<void> {
		if (!this._donkeyInsurance) {
			return;
		}
		try {
			const payload: IDonkeyInsurancePayload = {
				raceId,
				horseId: this._donkeyInsurance,
			};
			const {data} = await this._teamApiProvider.applyDonkeyInsurance(payload);
			runInAction(() => {
				this._savedTeam = data.success.team;
				this._team = data.success.team;
				this._donkeyInsurance = data.success.team.donkeyInsurance;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public async fetchDonkeyInsuranceCount(): Promise<void> {
		try {
			const {data} = await this._teamApiProvider.fetchDonkeyInsurance();
			runInAction(() => {
				this._donkeyInsuranceCount = data.donkeyInsuranceCount;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public async viewTeamChanged(raceId: number): Promise<void> {
		try {
			const {data} = await this._teamApiProvider.viewTeamChanged(raceId);
			runInAction(() => {
				this._savedTeam = data.success.team;
				this._team = data.success.team;
				this._donkeyInsurance = data.success.team.donkeyInsurance;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public selectCaptain(horseId: number): void {
		this._team.captain = horseId;
	}

	@action
	public clearTeam(): void {
		this._team = {
			...this._team,
			[TeamPosition.FirstPlace]: null,
			[TeamPosition.SecondPlace]: null,
			[TeamPosition.ThirdPlace]: null,
			winningDistance: null,
			captain: null,
		};
	}

	@action
	public async fetchTeamForRace(raceId: number): Promise<void> {
		this.clearTeam();
		try {
			this._isFetching = true;
			const {data} = await this._teamApiProvider.fetchTeam(raceId);

			runInAction(() => {
				this._savedTeam = data.success.team;
				this._team = data.success.team;
				this._donkeyInsurance = data.success.team.donkeyInsurance;
				this._donkeyInsuranceCount = data.success.team.donkeyInsuranceAllowedCount;
			});
		} catch (e) {
			runInAction(() => {
				this._savedTeam = undefined;
				this._team = {
					captain: null,
					[TeamPosition.FirstPlace]: null,
					[TeamPosition.SecondPlace]: null,
					[TeamPosition.ThirdPlace]: null,
					winningDistance: null,
				};
				this._donkeyInsurance = null;
				this._donkeyInsuranceCount = 0;
			});
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isFetching = false;
			});
		}
	}

	private get isAllocateTieBreaker() {
		return !this.team.winningDistance && this.isTeamHorseFull;
	}

	private get isAllocateCaptain() {
		return this.team.winningDistance && !this.team.captain && this.isTeamHorseFull;
	}

	@action
	public addHorseToTeam(payload: IHorseToTeamPayload) {
		if (!payload.horseId || !payload.position) {
			return;
		}

		this._team[payload.position] = payload.horseId;

		if (this.isAllocateTieBreaker) {
			setTimeout(() => {
				ScrollHelper.TIE_BREAKER_SCROLL();
			}, 300);
		}
	}

	public checkCaptainHighlight = debounce(() => this.checkCaptain(), 1000);

	private checkCaptain() {
		if (this.isAllocateCaptain) {
			setTimeout(() => {
				ScrollHelper.CAPTAIN_SCROLL();
			}, 300);
		}
	}

	@action
	public async saveTeam(raceId: number): Promise<void> {
		try {
			this._team.race = raceId;
			const {data} = await this._teamApiProvider.saveTeam(this.teamForSave);

			if (this._donkeyInsurance !== null && this._donkeyInsurance !== undefined) {
				void this.saveDonkeyInsurance(raceId);
			}

			runInAction(() => {
				this._savedTeam = data.success.team;
				this._team = data.success.team;
				this._donkeyInsurance = data.success.team.donkeyInsurance;
				this._donkeyInsuranceCount = data.success.team.donkeyInsuranceAllowedCount;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public removeHorseByPosition(position: TeamPosition) {
		this._team[position] = null;
		this.checkTeamConsistence();
	}

	@action
	public removeHorseById(horseId: number) {
		if (horseId === this.team.captain) {
			this._team.captain = null;
		}

		switch (horseId) {
			case this.team[TeamPosition.FirstPlace]:
				this._team[TeamPosition.FirstPlace] = null;
				return;
			case this.team[TeamPosition.SecondPlace]:
				this._team[TeamPosition.SecondPlace] = null;
				return;
			case this.team[TeamPosition.ThirdPlace]:
				this._team[TeamPosition.ThirdPlace] = null;
				return;
		}

		this.checkTeamConsistence();
	}

	public getIsHorseInTeam(horseId: number): boolean {
		return [
			this.team[TeamPosition.FirstPlace],
			this.team[TeamPosition.SecondPlace],
			this.team[TeamPosition.ThirdPlace],
		].includes(horseId);
	}

	public getIsHorseCaptain(horseId: number): boolean {
		if (!this.team.captain) {
			return false;
		}
		return horseId === this.team.captain;
	}

	@action
	public updateWinningDistance(value: number | undefined): void {
		const val = get(TIE_BREAKER_RANGE_MAPPER, String(value)) as string;
		const numberVal = Number(val || value);
		this._team.winningDistance = value === undefined ? null : numberVal;

		this.checkCaptainHighlight();
	}

	@action
	private checkTeamConsistence(): void {
		if (!this.isTeamHorseFull) {
			this.checkCaptainConsistence();
		}
		const isInsuranceInTeam = this.horseIds.includes(this.donkeyInsurance || 0);
		if (!isInsuranceInTeam) {
			this._donkeyInsurance = null;
		}
	}

	private checkCaptainConsistence(): void {
		if (!this._team.captain) {
			return;
		}

		const isCaptainInTeam = this.getIsHorseInTeam(this._team.captain);
		if (!isCaptainInTeam) {
			this._team.captain = null;
		}
	}
}
