import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeam} from "data/types/team";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";

export interface IBoosterButtonController extends ViewController {
	openBoosters: () => void;

	get isVisible(): boolean;

	get isEdit(): boolean;
}

@injectable()
export class BoosterButtonController implements IBoosterButtonController {
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get isVisible(): boolean {
		if (this.isLockout || this._horsesStore.isNoHorsesForBooster) {
			return false;
		}

		return this.isSaveAvailable;
	}

	get isEdit(): boolean {
		if (this._teamStore.donkeyInsurance === null) {
			return false;
		}
		return Boolean(this.team.donkeyInsurance);
	}

	private get isLockout() {
		return this._racesStore.isRaceLocked;
	}

	private get team() {
		return this._teamStore.team as ITeam;
	}

	private get isSaveAvailable(): boolean {
		return this.isCaptainSelected && this.isTeamFullFilled;
	}

	private get isTeamFullFilled(): boolean {
		return this._teamStore.isTeamHorseFull && this._teamStore.winningDistance !== undefined;
	}

	private get isCaptainSelected(): boolean {
		return Boolean(this._teamStore.team.captain);
	}

	public openBoosters = () => {
		this._modalsStore.showModal(ModalType.DONKEY_INSURANCE);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
