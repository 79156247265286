import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ChecksumType} from "data/enums";
import type {IChecksums, IChecksumStore} from "data/stores/checksum/checksum.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

interface IProps {
	type?: ChecksumType;
}

export interface ILiveScoreController extends ViewController<IProps> {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	protected readonly _actions: IChecksumAction;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable private _type: ChecksumType = ChecksumType.Fantasy;
	@observable private _intervalTiming: number = LIVE_SCORING_FETCH_TIMING;

	constructor(
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this.fetchChecksumAccordingType();

		this._interval = setInterval(() => {
			void this.fetchChecksumAccordingType();
		}, this._intervalTiming);
	}

	@action
	private checkInterval() {
		if (this._type === ChecksumType.Common) {
			this._intervalTiming = LIVE_SCORING_FETCH_TIMING * 5;
		}
	}

	private fetchChecksumAccordingType() {
		switch (this._type) {
			case ChecksumType.Fantasy:
				void this._checksumStore.fetchChecksums();
				break;
			case ChecksumType.Common:
				void this._checksumStore.fetchCommonChecksums();
				break;
			default:
				void this._checksumStore.fetchChecksums();
		}
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	callActions() {
		Object.keys(this.updatedChecksum).forEach((key) => {
			const action = this._actions[key];
			if (action && typeof action === "function") {
				void action();
				return;
			}

			const numberKey = Number(key);
			const isNumber = Boolean(numberKey);

			if (isNumber) {
				this.checkRaceAndFetch(numberKey);
			}
		});
	}

	dispose(): void {
		this.unsubscribeLiveScoring();
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(props: IProps): void {
		this._type = props.type || ChecksumType.Fantasy;
		this.checkInterval();
		this.subscribeLiveScoring();

		this.callActions();

		const subscription = reaction(
			() => this.updatedChecksum,
			() => this.callActions()
		);

		this.subscriptions$.push(subscription);
	}

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			races: () => this._racesStore.fetchRaces(),
			settings: () => this._settingsStore.fetchSettings(),
		};
	}

	private checkRaceAndFetch(raceId: number) {
		const selectedRaceId = this._racesStore.selectedRace?.id;
		if (selectedRaceId !== raceId || !raceId) {
			return;
		}

		void this._horsesStore.fetchHorses(raceId);
	}
}
