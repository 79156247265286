import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";

interface IProps {
	leagueId: number;
}

export interface ILeagueSettingsController extends ViewController<IProps> {
	get league(): ILeague | null;
}

@injectable()
export class LeagueSettingsController implements ILeagueSettingsController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;

	@observable private _requestsStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		Promise.all([this._leaguesStore.fetchLeagueUsers({leagueId})])
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));
	}

	dispose() {
		this._leagueDisposer?.();
	}

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => () => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action private createErrorHandlerOf =
		(type: keyof typeof this._requestsStates) => (error: AxiosError<IApiResponse>) => {
			this._requestsStates[type] = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		};

	@action private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};
}
