import React from "react";
import {observer} from "mobx-react";
import {
	ModalCloseBlock,
	ModalContent,
	ModalLogoWrapper,
	ModalText,
	ModalTitle,
} from "views/components/modals/styled";
import {Button, Checkbox, FormControl, ListItemText, MenuItem, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IModalSecondOptController} from "views/components/modals/modal_second_opt/modal_second_opt.controller";
import {ReactComponent as ModalLogo} from "assets/img/logo/modal_logo.svg";
import styled from "@emotion/styled";
import {Select} from "views/components/form";

const Content = styled.div`
	max-width: 400px;
	margin: 0 auto;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;

	h2 {
		margin-bottom: 0;
	}
`;

const Note = styled.p`
	color: var(--primaryColorDark);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 16.8px */
`;

const SMenuItem = styled(MenuItem)`
	display: flex;
	justify-content: space-between;
	gap: 12px;
	font-size: 14px;

	span {
		white-space: break-spaces;
		font-size: 14px;
	}

	a {
		text-decoration: underline;
	}

	@media screen and (max-width: 840px) {
		font-size: 12px;

		span {
			font-size: 12px;
		}
	}
`;

export const ModalSecondOpt: React.FC = observer(() => {
	const {
		isOpen,
		value,
		federations,
		isAllSelected,
		isSubmitDisabled,
		getFederationNameByName,
		handleSelectChange,
		close,
		confirm,
	} = useViewController<IModalSecondOptController>(Bindings.ModalSecondOptController);

	return (
		<Modal open={isOpen}>
			<ModalContent className="full-screen">
				<ModalCloseBlock>
					<Button
						aria-label="close"
						variant="contained"
						color="primary"
						sx={{minWidth: 0}}
						onClick={close}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>

				<ModalLogoWrapper>
					<ModalLogo />
				</ModalLogoWrapper>

				<Content>
					<ModalTitle>Pick Your Choice of Federations</ModalTitle>
					<ModalText>
						Crown Jewels Racing will only share information with trusted partners
						subject to your consent.
					</ModalText>
					<ModalText>
						You can find a list of our trusted partners and more information on their
						practise below.
					</ModalText>
					<ModalText>
						You can consent or oppose to data sharing by checking / unchecking the boxes
						for each partner:
					</ModalText>

					<FormControl sx={{mt: 2}} fullWidth>
						<Select
							multiple
							label="Pick Your Federations"
							placeholder="Pick Your Federations"
							variant="standard"
							name="federations"
							type="text"
							value={value}
							renderValue={(selected: unknown) =>
								(selected as string[])
									.map((name) => getFederationNameByName(name))
									.join(", ")
							}
							onChange={handleSelectChange}
							required={true}>
							<SMenuItem value="all">
								<Checkbox checked={isAllSelected} />
								<ListItemText primary="All" />
							</SMenuItem>
							{federations.map((federation) => (
								<SMenuItem key={federation.name} value={federation.name}>
									<Checkbox checked={value.indexOf(federation.name) > -1} />
									<ListItemText primary={federation.name} />
									<a
										href={federation.terms}
										target="_blank"
										rel="noreferrer noopener">
										Privacy Policy
									</a>
								</SMenuItem>
							))}
						</Select>
					</FormControl>
					<Button onClick={confirm} disabled={isSubmitDisabled} sx={{my: 2}}>
						Confirm Federation
					</Button>
					<Note>
						Once yo have agreed to the sharing of your information with a specific
						partner, you can still withdraw your consent to the processing of your data
						by a specific partner at anytime. In order to do so, please contact them
						directly by referring to their privacy policy listed above.
					</Note>
				</Content>
			</ModalContent>
		</Modal>
	);
});
