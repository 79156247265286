import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {LeagueStatus, RaceStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRanking} from "data/providers/api/rankings.api.provider";
import type {IApiResponse} from "data/services/http";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {uniqBy} from "lodash";

interface IParams {
	leagueId: number;
}

export interface ILeagueTableController extends ViewController<IParams> {
	loadMore: () => void;
	getIsUserRow: (userId: number | null) => string;

	get i18n(): ILocalizationStore;

	get isLoading(): boolean;

	get hasNextPage(): boolean;

	get rankings(): IRanking[];

	get isComplete(): boolean;

	get selectedRace(): number | null;

	get selectedRaceId(): number | undefined;

	get isScoringStarted(): boolean;

	get rankingsUser(): IRanking | null;

	get isUserInRow(): boolean;

	get isOverall(): boolean;
}

@injectable()
export class LeagueTableController implements ILeagueTableController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _leagueId: number | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOverall(): boolean {
		return !this._rankingsStore.selectedRace || this._rankingsStore.selectedRace === "overall";
	}

	get isLoading(): boolean {
		return this._rankingsStore.isLoading;
	}

	get isScoringStarted(): boolean {
		const league = this.league;
		if (!league) {
			return false;
		}
		return league.status !== LeagueStatus.SCHEDULED;
	}

	get rankingsUser(): IRanking | null {
		return this._rankingsStore.rankingUser;
	}

	get isUserInRow(): boolean {
		return this.rankings.some((e) => e.userId === this._userStore.user?.id);
	}

	get pinnedUsers(): IRanking[] {
		return this._rankingsStore.pinnedUsers;
	}

	private get pinnedUsersIds(): number[] {
		return this.pinnedUsers.map((e) => e.userId).filter(Boolean) as number[];
	}

	get isComplete(): boolean {
		const race = this.selectedRace;

		const raceEntity = this._racesStore.getRaceByFeedId(race as number);
		return raceEntity?.status === RaceStatus.Complete;
	}

	get hasNextPage(): boolean {
		return this._rankingsStore.hasNextPage;
	}

	get rankings(): IRanking[] {
		return uniqBy(
			[...this._rankingsStore.pinnedUsers, ...this._rankingsStore.rankings],
			"userId"
		);
	}

	get selectedRace(): number | null {
		if (this._rankingsStore.selectedRace === "overall") {
			return null;
		}
		return this._rankingsStore.selectedRace || null;
	}

	get selectedRaceId(): number | undefined {
		if (!this.selectedRace) {
			return;
		}

		return this._racesStore.getRaceByFeedId(this.selectedRace)?.id;
	}

	private get league() {
		return this._leaguesStore.getLeagueById(this._leagueId || -1);
	}

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this.initParams(param);
		this._rankingsStore.clearRankings();

		const subscription = reaction(
			() => [this._leagueId, this.selectedRace],
			() => this.fetchRankings(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);
	}

	onChange(param: IParams): void {
		this.initParams(param);
	}

	@action
	public fetchRankings = () => {
		const raceId = this.selectedRace;
		if (!this._leagueId) {
			return;
		}

		this._rankingsStore
			.fetchLeagueRankings({leagueId: this._leagueId, raceFeedId: raceId})
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error));
	};

	public loadMore = () => {
		const raceId = this.selectedRace;

		if (!this._leagueId) {
			return;
		}

		this._rankingsStore
			.fetchMoreLeagueRankings({leagueId: this._leagueId, raceFeedId: raceId})
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error));
	};

	public getIsUserRow = (userId: number | null): string => {
		if (this.pinnedUsersIds.includes(userId || -1)) {
			return "celebrity";
		}
		return userId === this._userStore.user?.id ? "user" : "";
	};

	private initParams(param: IParams) {
		this._leagueId = param.leagueId;
	}
}
