import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {deburr, isEqual, lowerCase, orderBy, sortBy, toLower} from "lodash";
import type {
	ISections,
	IStaticContentStore,
	TArticle,
} from "data/stores/static_content/static_content.store";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	readonly i18n: ILocalizationStore;
	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (tabId: number) => boolean;
	findSectionsById: (sectionId: number) => TArticle[];

	get tabs(): ISections["sections"];

	get contents(): TArticle[];

	get currentTab(): number | null;

	get className(): string;

	get isPrizes(): boolean;
}

@injectable()
export class HelpController implements IHelpController {
	private static rootPath = "/help";
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _currentTab: number | null = null;
	@observable private _navigate!: IControllerProps["navigate"];
	@observable private _pathname: string = "";

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	get faqName(): string {
		return this._pathname.toLowerCase().includes("faq") ? "faq" : "";
	}
	get isPrizes(): boolean {
		return this._pathname.toLowerCase().includes("prize");
	}
	get className(): string {
		if (this._pathname.toLowerCase().includes("faq")) {
			return "faq";
		}
		if (this.isPrizes) {
			return "prizes";
		}
		return "";
	}

	get tabs() {
		return sortBy(this._staticContentStore.sections, "position");
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	get currentTab() {
		return this._currentTab;
	}

	public findSectionsById = (sectionId: number) => {
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	};

	@action
	onChange(param: IControllerProps) {
		this._navigate = param.navigate;
		this._pathname = param.pathname;
		this.updatePathname(param.pathname);
	}

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (tabId: number) => {
		const sections = this.findSectionsById(tabId);

		return Boolean(
			sections.find(
				({label_names, title}) =>
					label_names.includes("contact") || lowerCase(title).includes("contact")
			)
		);
	};

	public buildPathName = (name: string) => {
		return toLower(deburr(name)).split(" ").join("-");
	};

	dispose() {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	async init({navigate, pathname}: IControllerProps) {
		this._navigate = navigate;
		this._pathname = pathname;

		await this._staticContentStore.fetchStaticContent().catch((_err) => {
			// TODO Show error modal
		});

		pathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;

		if (isEqual(pathname, HelpController.rootPath)) {
			return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		}

		this.updatePathname(pathname);
	}

	private updatePathname = (pathname: string) => {
		const newTab = this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id;

		if (newTab) {
			this.setCurrentTab(newTab);
		}

		this.checkPathName();
	};

	private subscribeOnPath() {
		const subscription = reaction(
			() => this._pathname,
			() => this.checkPathName(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);
	}

	private checkPathName() {
		if (this._pathname === "/help") {
			this._navigate(this.buildPathName(this.tabs[0].name), {replace: true});
			this._pathname = this.buildPathName(this.tabs[0].name);
		}
	}
}
