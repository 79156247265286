import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {LeaguePrivacy, ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {NavigateFunction} from "react-router-dom";

interface IParams {
	leagueId: number;
	navigate: NavigateFunction;
}

export interface ISectionLeagueLeaveController extends ViewController<IParams> {
	leaveLeague: () => void;
	joinLeague: () => void;

	get i18n(): ILocalizationStore;

	get league(): ILeague | undefined | null;

	get isCommissioner(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class SectionLeagueLeaveController implements ISectionLeagueLeaveController {
	@observable private _isLoading: boolean = false;
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _leagueId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isCommissioner(): boolean {
		return this.league?.leagueManager?.userId === this._userStore.user?.id;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._leagueId = param.leagueId;
		this._navigate = param.navigate;
	}

	onChange(param: IParams): void {
		this._leagueId = param.leagueId;
		this._navigate = param.navigate;
	}

	@action leaveLeague = () => {
		const league = this.league;
		if (!league) {
			return;
		}

		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league.leave.confirm",
				"Do you really want to leave this league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this._isLoading = true;

				this._leaguesStore
					.leaveLeague({leagueId: league.id})
					.then(() => this.checkLeagueAndRedirect(league))
					.catch(this.onError)
					.finally(() => {
						runInAction(() => {
							this._isLoading = false;
						});
					});
			},
		});
	};

	private checkLeagueAndRedirect(league: ILeague) {
		if (league.privacy === LeaguePrivacy.PRIVATE) {
			this._navigate?.("/leagues");
		}
	}

	@action joinLeague = () => {
		const league = this.league;
		const code = league?.code;

		if (!code || !league) return;

		this._isLoading = true;

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: league.id}))
			.catch(this.onError)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};
}
