import React, {useCallback, useState} from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {ITutorialStep} from "data/stores/tutorial/tutorial.store";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Button} from "@mui/material";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 12px;
	gap: 8px;

	.arrow {
		min-width: unset;
		max-width: 24px;
		width: 24px;
		height: 24px;
		background: transparent;
		color: var(--primaryColorDark);
		border-color: transparent !important;

		&:active,
		&:hover,
		&:focus {
			color: var(--primaryColorDark);
			background: transparent;
			border-color: transparent !important;
		}

		&:disabled {
			background: transparent !important;
			border-color: transparent !important;
		}
	}
`;

const CenterBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	position: relative;
`;

const Step = styled.button`
	width: 12px !important;
	height: 12px;
	padding: 0;
	border-radius: 50%;
	background: #dbdbdb;
	border: none;
	transition: 0.25s;

	&.current {
		width: calc(16px * 2 + 12px) !important; // 2 steps dots + gap between
		height: 16px;
		border-radius: 37px;
		background: var(--primaryColorDark);
	}
`;

interface IProps {
	steps: ITutorialStep[];
	currentStep: number;
	goToStep: (currentIndex: number, nextIndex: number) => void;
}

export const TutorialSteps: React.FC<IProps> = observer(({steps, currentStep, goToStep}) => {
	const [isDisabled, setIsDisabled] = useState(false);
	const handleStepClick = useCallback(
		(event: React.SyntheticEvent<HTMLButtonElement>) => {
			if (!goToStep || typeof goToStep !== "function") {
				return;
			}
			const index = Number(event.currentTarget.dataset.step);
			if (index === null || index === undefined || Number.isNaN(index)) {
				return;
			}

			goToStep(currentStep, index);
		},
		[goToStep, currentStep]
	);

	const getStepClass = useCallback(
		(step: number) => {
			if (step < currentStep) {
				return "previous";
			}
			return currentStep === step ? "current" : "";
		},
		[currentStep]
	);

	const handleDisabled = useCallback(() => {
		setIsDisabled(true);

		setTimeout(() => {
			setIsDisabled(false);
		}, 450);
	}, []);

	const toNextStep = useCallback(() => {
		if (!goToStep || typeof goToStep !== "function") {
			return;
		}
		handleDisabled();
		goToStep(currentStep, currentStep + 1);
	}, [currentStep, goToStep, handleDisabled]);

	const toPrevStep = useCallback(() => {
		if (!goToStep || typeof goToStep !== "function") {
			return;
		}
		handleDisabled();
		goToStep(currentStep, currentStep - 1);
	}, [currentStep, goToStep, handleDisabled]);

	return (
		<Wrapper>
			<Button onClick={toPrevStep} disabled={isDisabled} className="arrow">
				<ArrowBackIosNewIcon />
			</Button>
			<CenterBlock>
				{steps.slice(0, steps.length).map((step, index) => (
					<Step
						key={step.text_loco}
						data-step={index + 1}
						className={getStepClass(index + 1)}
						onClick={handleStepClick}
					/>
				))}
			</CenterBlock>
			<Button onClick={toNextStep} disabled={isDisabled} className="arrow">
				<ArrowForwardIosIcon />
			</Button>
		</Wrapper>
	);
});
