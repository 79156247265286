import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRace} from "data/types/race";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {SelectChangeEvent} from "@mui/material";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {RankingsSortValue} from "data/enums";

export interface ILeaderboardFiltersController extends ViewController {
	handleSelectChange: (event: SelectChangeEvent<unknown>) => void;

	get i18n(): ILocalizationStore;

	get races(): IRace[];

	get selectedRace(): number | null | "overall";
}

@injectable()
export class LeaderboardFiltersController implements ILeaderboardFiltersController {
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore
	) {
		makeAutoObservable(this);
	}

	get races(): IRace[] {
		return this._racesStore.completedList;
	}

	get selectedRace() {
		return this._rankingsStore.selectedRace;
	}

	public handleSelectChange = (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value as number | "overall";
		const order =
			!value || value === "overall"
				? RankingsSortValue.OverallPoints
				: RankingsSortValue.RacePoints;

		this._rankingsStore.sortFilters = {
			...this._rankingsStore.sortFilters,
			value: order,
		};

		this._rankingsStore.selectedRace = value || null;
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => [this._rankingsStore.selectedRace, this._rankingsStore.sortFilters],
			() => this.fetchRankings(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	private fetchRankings(): void {
		void this._rankingsStore.fetchRankings();
	}
}
