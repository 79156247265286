export abstract class StorageHelper {
	public static GET<T>(key: string) {
		return JSON.parse(localStorage.getItem(key) || "null") as T | null;
	}

	public static SET<T>(key: string, value: T) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	public static REMOVE(key: string) {
		localStorage.removeItem(key);
	}
}
