import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IGeoBlockController} from "views/components/geo_block/geo_block.controller";
import {Bindings} from "data/constants/bindings";
import {PageLayout, PageWrapper} from "views/components/common";
import {Modal} from "@mui/material";
import {
	ModalContent,
	ModalLogoWrapper,
	ModalText,
	ModalTitle,
} from "views/components/modals/styled";
import {ReactComponent as ModalLogo} from "assets/img/logo/modal_logo.svg";
import styled from "@emotion/styled";

const SPageWrapper = styled(PageWrapper)`
	min-height: 100vh;
`;

export const GeoBlock: React.FC<PropsWithChildren> = observer(({children}) => {
	const {i18n, isBlocked} = useViewController<IGeoBlockController>(Bindings.GeoBlockController);

	if (!isBlocked) {
		return children;
	}

	return (
		<SPageWrapper>
			<PageLayout>
				<Modal open={true}>
					<ModalContent>
						<ModalLogoWrapper>
							<ModalLogo />
						</ModalLogoWrapper>
						<ModalTitle>{i18n.t("modal.blocking.title", "We're sorry.")}</ModalTitle>
						<ModalText>
							{i18n.t(
								"modal.blocking.text",
								"Crown Jewels Racing Fantasy is unavailable in your location."
							)}
						</ModalText>
					</ModalContent>
				</Modal>
			</PageLayout>
		</SPageWrapper>
	);
});
