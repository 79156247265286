import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IEventsStore} from "data/stores/events/events.store";

export interface IModalTeamSavedController extends ViewController {
	get i18n(): ILocalizationStore;
	get isOpen(): boolean;
	get isWithoutBoosterCopy(): boolean;
	close: () => void;
	openBooster: () => void;
	openSchedule: () => void;
}

@injectable()
export class ModalTeamSavedController implements IModalTeamSavedController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};

	public openBooster = () => {
		this.close();
		this._modalsStore.showModal(ModalType.DONKEY_INSURANCE);
	};

	public openSchedule = () => {
		this.close();
		this._eventsStore.isScheduleOpen = true;
	};

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVED;
	}

	get isWithoutBoosterCopy(): boolean {
		return this._horsesStore.isNoHorsesForBooster;
	}
}
