import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {INotificationController} from "views/components/notification/notification.controller";
import {Bindings} from "data/constants/bindings";
import {useLocation} from "react-router";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	z-index: 90;
	background: #823131;
	gap: 2px;
`;

const NotificationElement = styled.div`
	background: var(--invalidColor);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px 12px;
	width: 100%;
	height: 100%;

	color: #ffffff;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
`;

const NO_NOTIFICATION_PATHS = ["/"];

export const Notification: React.FC = observer(() => {
	const location = useLocation();
	const isNoNotificationPath = NO_NOTIFICATION_PATHS.some((path) => path === location.pathname);

	const {notification} = useViewController<INotificationController>(
		Bindings.NotificationController
	);

	if (!notification || isNoNotificationPath) {
		return null;
	}

	return (
		<Wrapper>
			<NotificationElement>{notification}</NotificationElement>
		</Wrapper>
	);
});
