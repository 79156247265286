import React from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "@mui/material";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import type {IModalConfirmController} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	ModalButtonsWrapper,
	ModalCloseBlock,
	ModalContent,
	ModalIcon,
	ModalText,
	ModalTitle,
} from "views/components/modals/styled";
import styled from "@emotion/styled";

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 0;
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<Button aria-label="close" sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>
				<ModalIcon>
					<ErrorIcon />
				</ModalIcon>
				<SModalTitle>
					{i18n.t(modalContent?.title ?? "modal.confirm.title", "Confirm")}
				</SModalTitle>
				<Exist when={modalContent?.message}>
					<ModalText>{modalContent?.message}</ModalText>
				</Exist>
				<ModalButtonsWrapper>
					<Button fullWidth variant="outlined" onClick={close}>
						{i18n.t(
							modalContent?.cancelText || "modal.confirm.cancel_button",
							"Cancel"
						)}
					</Button>
					<Exist when={modalContent?.callback}>
						<Button fullWidth variant="contained" onClick={modalContent?.callback}>
							{i18n.t(
								modalContent?.confirmText || "modal.confirm.confirm_button",
								"Confirm"
							)}
						</Button>
					</Exist>
				</ModalButtonsWrapper>
			</ModalContent>
		</Modal>
	);
});
