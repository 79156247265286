import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {
	ILeagueRankingsPayload,
	IRanking,
	IRankingsApiProvider,
	IRankingsPayload,
} from "data/providers/api/rankings.api.provider";
import {Bindings} from "data/constants/bindings";
import {DEFAULT_RANKINGS_LIMIT} from "data/constants";
import {RankingsSortValue, SortOrder} from "data/enums";
import type {IRankingSortFilter} from "data/types/filter";

export interface IRankingsStore {
	get isLoading(): boolean;

	get rankings(): IRanking[];

	get rankingUser(): IRanking | null;

	get hasNextPage(): boolean;

	get selectedRace(): number | null | "overall";

	set selectedRace(value: number | null | "overall");

	get sortFilters(): IRankingSortFilter;

	set sortFilters(value: IRankingSortFilter);

	get pinnedUsers(): IRanking[];

	fetchRankings(): Promise<void>;

	fetchMoreRankings(): Promise<void>;

	clearRankings(): void;

	fetchLeagueRankings(payload: ILeagueRankingsPayload): Promise<void>;

	fetchMoreLeagueRankings(payload: ILeagueRankingsPayload): Promise<void>;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _rankings: IRanking[] = [];
	@observable private _rankingUser: IRanking | null = null;
	@observable private _hasNextPage: boolean = false;
	@observable private _isLoading: boolean = false;
	@observable private _pinnedUsers: IRanking[] = [];
	@observable private _currentPage = 1;
	@observable private _selectedRace: number | null | "overall" = null;
	@observable private _sortFilters: IRankingSortFilter = {
		order: SortOrder.DESC,
		value: RankingsSortValue.OverallPoints,
	};

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider
	) {
		makeAutoObservable(this);
	}

	get pinnedUsers(): IRanking[] {
		return this._pinnedUsers;
	}

	get sortFilters(): IRankingSortFilter {
		return this._sortFilters;
	}

	set sortFilters(value: IRankingSortFilter) {
		this._sortFilters = value;
	}

	get selectedRace(): number | null | "overall" {
		return this._selectedRace;
	}

	set selectedRace(value: number | null) {
		this._selectedRace = value;
	}

	get rankingUser(): IRanking | null {
		return this._rankingUser;
	}

	get rankings(): IRanking[] {
		return this._rankings;
	}

	get hasNextPage(): boolean {
		return this._hasNextPage;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	private get requestPayload(): IRankingsPayload {
		const isOverall = this._selectedRace === "overall";
		return {
			raceFeedId: isOverall ? null : (this._selectedRace as number | null),
			page: this._currentPage,
			limit: DEFAULT_RANKINGS_LIMIT,
			dir: this.sortFilters.order,
			order: this.sortFilters.value,
		};
	}

	@action
	public async fetchRankings(): Promise<void> {
		this._isLoading = true;
		this.clearRankings();

		try {
			const {data} = await this._rankingsApiProvider.rankings(this.requestPayload);
			runInAction(() => {
				this._rankingUser = data.success.user;
				this._rankings = data.success.rankings;
				this._hasNextPage = data.success.nextPage;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	@action
	public async fetchMoreRankings(): Promise<void> {
		this._isLoading = true;
		try {
			this._currentPage += 1;
			const {data} = await this._rankingsApiProvider.rankings(this.requestPayload);
			runInAction(() => {
				this._rankingUser = data.success.user;
				this._rankings = [...this._rankings, ...data.success.rankings];
				this._hasNextPage = data.success.nextPage;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	@action
	public async fetchLeagueRankings(payload: ILeagueRankingsPayload): Promise<void> {
		this._isLoading = true;
		this.clearRankings();

		try {
			const {data} = await this._rankingsApiProvider.leagueRankings(payload);
			runInAction(() => {
				this._rankingUser = data.success.user;
				this._rankings = data.success.rankings;
				this._pinnedUsers = data.success.pinnedUsers;
				this._hasNextPage = data.success.nextPage;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	@action
	public async fetchMoreLeagueRankings(payload: ILeagueRankingsPayload): Promise<void> {
		this._isLoading = true;
		try {
			this._currentPage += 1;
			const {data} = await this._rankingsApiProvider.leagueRankings({
				...payload,
				page: this._currentPage,
			});
			runInAction(() => {
				this._rankingUser = data.success.user;
				this._rankings = [...this._rankings, ...data.success.rankings];
				this._pinnedUsers = data.success.pinnedUsers;
				this._hasNextPage = data.success.nextPage;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	@action
	public clearRankings() {
		this._rankings = [];
		this._hasNextPage = false;
		this._rankingUser = null;
		this._currentPage = 1;
	}
}
