import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";
import type {IHorsePoints, IHorseRacePoints} from "data/types/team";

export interface IHorsePointsStore {
	get isLoading(): boolean;

	get horsePoints(): IHorsePoints | undefined;

	clearPoints(): void;

	getHorsePointsByRace(raceId: number): IHorseRacePoints | undefined;

	fetchHorsePoints(horseFeedId: number): Promise<void>;
}

@injectable()
export class HorsePointsStore implements IHorsePointsStore {
	@observable private _isLoading: boolean = false;
	@observable private _horsePoints: IHorsePoints | undefined;

	constructor(@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider) {
		makeAutoObservable(this);
	}

	get horsePoints(): IHorsePoints | undefined {
		return this._horsePoints;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	public getHorsePointsByRace(raceId: number) {
		const raceNo = String(raceId);
		return this._horsePoints?.races[raceNo];
	}

	@action
	public clearPoints() {
		this._horsePoints = undefined;
	}

	@action
	public async fetchHorsePoints(horseFeedId: number): Promise<void> {
		try {
			const {data} = await this._teamApiProvider.fetchHorsePoints(horseFeedId);
			runInAction(() => {
				this._horsePoints = data.success.horsePoints;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
