import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IMenuController extends ViewController {
	closeMenu: () => void;
	logout: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isAuthorized(): boolean;
}

@injectable()
export class MenuController implements IMenuController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private readonly _userStore: IUserStore,
		@inject(Bindings.MenuStore) private readonly _menuStore: IMenuStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get isOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	public closeMenu = () => {
		this._menuStore.closeMenu();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public logout = () => {
		this._userStore
			.logout()
			.then(() => (window.location.href = "/"))
			.finally(() => this.closeMenu());
	};
}
