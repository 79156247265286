import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ModalType, RequestState} from "data/enums";
import {AxiosError} from "axios";
import {first, isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import {Bindings} from "data/constants/bindings";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IApiResponse} from "data/services/http";
import {NavigateFunction, useNavigate} from "react-router-dom";
import React from "react";

interface IParams {
	navigate: NavigateFunction;
}

export interface IMyLeaguesController extends ViewController<IParams> {
	goToLeague: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	loadMoreMyLeagues: () => void;
	getLeagueClassName: (id: number) => string;
	getLeagueNewsLink: (id: number) => string | undefined;

	get i18n(): ILocalizationStore;

	get leagues(): ILeague[];

	get isLoading(): boolean;

	get hasNextPage(): boolean;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable _requestState = RequestState.PENDING;
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get leagues() {
		return this._leaguesStore.myLeagues.leagues;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get hasNextPage(): boolean {
		return this._leaguesStore.myLeagues.nextPage;
	}

	public loadMoreMyLeagues = () => {
		this._leaguesStore.fetchMoreMyLeagues().then(this.onSuccess).catch(this.onError);
	};

	dispose() {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	@action init(param: IParams) {
		this._navigate = param.navigate;
		const subscription = reaction(
			() => [this.leagues, this.isLoading],
			() => this.checkLeagues(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);

		this._leaguesStore.fetchMyLeagues().then(this.onSuccess).catch(this.onError);
	}

	onChange(param: IParams) {
		this._navigate = param.navigate;
	}

	public getLeagueClassName = (id: number) => {
		const league = this.leagues.find((e) => e.id === id);
		return league?.isPinned ? "celebrity" : "";
	};

	public getLeagueNewsLink = (id: number) => {
		const league = this.leagues.find((e) => e.id === id);
		const firstPinnedUser = first(league?.pinnedUsers);
		return firstPinnedUser?.link;
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	public goToLeague = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		const id = Number(event.currentTarget.dataset.id);

		if ((event.target as Element)?.tagName.toLowerCase() === "a" || !id) {
			return;
		}

		this._navigate?.(`/league/${id}`);
	};

	private checkLeagues() {
		if (!this.leagues.length && !this.isLoading) {
			this._navigate?.("/leagues/join");
		}
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};
}
