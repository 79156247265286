import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RaceStatus, TeamPosition} from "data/enums";
import type {IPlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import type {ITeamPositionCommonProps} from "data/types/team";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IRacesStore} from "data/stores/races/races.store";
import {isEmpty} from "lodash";

export interface ITeamHorseEmptyController extends ViewController<ITeamPositionCommonProps> {
	openPlayerPool: () => void;

	get i18n(): ILocalizationStore;

	get isLiveOrComplete(): boolean;

	get isLocked(): boolean;

	get activeClass(): string;

	get isEmpty(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class TeamHorseEmptyController implements ITeamHorseEmptyController {
	@observable private _position: TeamPosition | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayerPoolStore) private _playerPoolStore: IPlayerPoolStore
	) {
		makeAutoObservable(this);
	}

	get isLocked(): boolean {
		return this.isNoHorsesAvailable || this.isRaceLocked;
	}

	get isLiveOrComplete(): boolean {
		const isLive = this._racesStore.selectedRace?.status === RaceStatus.Playing;
		return isLive || this.isComplete;
	}

	get isComplete(): boolean {
		return this._racesStore.isRaceComplete;
	}

	get activeClass(): string {
		return this._position === this._playerPoolStore.selectedPosition ? "active" : "";
	}

	get isLoading(): boolean {
		return this._teamStore.isFetching;
	}

	get isEmpty(): boolean {
		return !this.horseId;
	}

	private get horseId(): number | undefined {
		if (!this._position) {
			return;
		}
		return this._teamStore.team?.[this._position] || undefined;
	}

	private get isRaceLocked() {
		return this._racesStore.isRaceLocked;
	}

	private get isNoHorsesAvailable(): boolean {
		const {isLoading, list} = this._horsesStore;
		if (isLoading) {
			return false;
		}

		return isEmpty(list);
	}

	public openPlayerPool = () => {
		this._playerPoolStore.openPlayerPool();
		if (this._position) {
			this._playerPoolStore.selectedPosition = this._position;
		}
	};

	dispose(): void {
		return;
	}

	init(param: ITeamPositionCommonProps): void {
		this._position = param.position;
	}

	onChange(param: ITeamPositionCommonProps): void {
		this._position = param.position;
	}
}
