import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";

export interface ILocalizationController extends ViewController {
	get i18n(): ILocalizationStore;
}

@injectable()
export class LocalizationController implements ILocalizationController {
	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {}
}
