import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IHorsePointsStore} from "data/stores/horse_points/horse_points.store";

export interface IHorseInfoRacesController extends ViewController {
	get raceIds(): number[];
}

@injectable()
export class HorseInfoRacesController implements IHorseInfoRacesController {
	constructor(@inject(Bindings.HorsePointsStore) private _horsePointsStore: IHorsePointsStore) {
		makeAutoObservable(this);
	}

	get raceIds(): number[] {
		return Object.keys(this._horsePointsStore.horsePoints?.races || {})
			.filter(Boolean)
			.map((e) => Number(e));
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
