import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {AxiosError} from "axios";
import {COUNTRY_BLOCKED_CODE} from "data/constants";
import {Bindings} from "data/constants/bindings";
import type {IUserApiProvider} from "data/providers/api/user.api.provider";

export interface IGeoBlockStore {
	ping(): Promise<void>;

	get isBlockedByCountry(): boolean;
}

@injectable()
export class GeoBlockStore implements IGeoBlockStore {
	@observable private _isBlockedByCountry: boolean = false;

	constructor(@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider) {
		makeAutoObservable(this);
	}

	get isBlockedByCountry(): boolean {
		return this._isBlockedByCountry;
	}

	@action
	public async ping(): Promise<void> {
		try {
			await this._userApi.ping();
		} catch (err) {
			const error = err as AxiosError;

			if (error.response?.status === COUNTRY_BLOCKED_CODE) {
				this.block();
			}
		}
	}

	@action
	private block() {
		this._isBlockedByCountry = true;
	}
}
