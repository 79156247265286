import React from "react";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IForgotPasswordPayload} from "data/providers/api/password.api.provider";
import type {NavigateFunction} from "react-router-dom";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IForgotPasswordForm extends HTMLFormElement {
	email: HTMLInputElement;
}

interface IParams {
	navigate: NavigateFunction;
}

export interface IForgotPasswordController extends ViewController<IParams> {
	handleFormSubmit: (event: React.SyntheticEvent<IForgotPasswordForm>) => void;
	forgotPassword: (params: IForgotPasswordPayload) => void;
	handleFormOnChange: () => void;
	close: () => void;

	get i18n(): ILocalizationStore;

	get error(): string | undefined;

	get isSuccess(): boolean | undefined;

	get isFormDisabled(): boolean;
}

@injectable()
export class ForgotPasswordController implements IForgotPasswordController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _error?: string = undefined;
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		return this._error;
	}

	get isSuccess() {
		return this._requestState === RequestState.SUCCESS;
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	public close = () => {
		this._navigate?.("/");
	};

	init(param: IParams): void {
		this._modalsStore.hideModal();
		this._navigate = param.navigate;
	}

	onChange?(param: IParams): void {
		this._navigate = param.navigate;
	}

	@action handleFormOnChange = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	@action forgotPassword(payload: IForgotPasswordPayload) {
		this._requestState = RequestState.PENDING;

		void this._userStore.forgotPassword(payload).then(this.onSuccess).catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<IForgotPasswordForm>) => {
		event.preventDefault();
		const {email} = event.currentTarget;

		if (!email.checkValidity()) {
			this._error = "Please provide a valid email address";
			return;
		}

		void this.forgotPassword({
			email: email.value,
		});
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._error = extractErrorMessage(error);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};
}
