import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {IHorse} from "data/types/race";
import {Bindings} from "data/constants/bindings";
import {isNumber, last, sortBy} from "lodash";
import type {IHorsesStore} from "data/stores/horses/horses.store";

export interface IHorseInfoRaceResultController extends ViewController {
	get firstHorse(): IHorse | undefined;

	get secondHorse(): IHorse | undefined;

	get thirdHorse(): IHorse | undefined;

	get lastHorse(): IHorse | undefined;
}

@injectable()
export class HorseInfoRaceResultController implements IHorseInfoRaceResultController {
	constructor(@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore) {
		makeAutoObservable(this);
	}

	get firstHorse(): IHorse | undefined {
		return this.getHorseByPosition(1);
	}

	get secondHorse(): IHorse | undefined {
		return this.getHorseByPosition(2);
	}

	get thirdHorse(): IHorse | undefined {
		return this.getHorseByPosition(3);
	}

	get lastHorse(): IHorse | undefined {
		const onlyPositionsHorses = this.list.filter((horse) => Boolean(horse.finishedPosition));
		const lastHorse = last(sortBy(onlyPositionsHorses, "finishedPosition"));
		return isNumber(lastHorse?.finishedPosition) ? lastHorse : undefined;
	}

	private get list() {
		return this._horsesStore.list;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	private getHorseByPosition(position: number) {
		return this.list.find((horse) => horse.finishedPosition === position);
	}
}
