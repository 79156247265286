import React from "react";
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import styled from "@emotion/styled";

const MenuButtonElement = styled(Button)`
	position: relative;
	top: 0;
	display: flex;
	flex-direction: column;
	gap: 3px;
	padding: 6px 3px;
	transition: 0.25s;
	width: 24px;
	height: 24px;
	min-width: 24px;
	background: transparent;
	border-radius: 0;
	border: none !important;

	&:hover,
	&:active,
	&:active {
		background: var(--primaryColorDark);
	}

	.bar-1,
	.bar-2,
	.bar-3 {
		width: 100%;
		height: 2px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		background: #ffffff;
		transition: 0.4s;
	}

	&.open {
		z-index: 992;

		.bar-1 {
			transform: translate(0, 5px) rotate(-45deg);
		}

		.bar-2 {
			opacity: 0;
		}

		.bar-3 {
			transform: translate(0, -5px) rotate(45deg);
		}
	}
`;

interface IProps {
	isOpen: boolean;
	onClick: () => void;
}

export const MenuButton: React.FC<IProps> = observer(({isOpen, onClick}) => {
	const className = isOpen ? "open" : "";

	return (
		<MenuButtonElement
			id="menu"
			aria-label="menu-button"
			className={className}
			onClick={onClick}>
			<div className={`bar-1 ${className}`} />
			<div className="bar-2" />
			<div className="bar-3" />
		</MenuButtonElement>
	);
});
