import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeam} from "data/types/team";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRacesStore} from "data/stores/races/races.store";
import {ModalType} from "data/enums";
import {AxiosError} from "axios/index";
import {IApiResponse} from "data/services/http";

export interface IModalTeamChangedController extends ViewController {
	close: () => void;

	get isOpen(): boolean;

	get i18n(): ILocalizationStore;
}

@injectable()
export class ModalTeamChangedController implements IModalTeamChangedController {
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_CHANGED;
	}

	private get team(): ITeam {
		return this._teamStore.team as ITeam;
	}

	private get raceId(): number | undefined {
		return this._racesStore.selectedRace?.id;
	}

	public close = () => {
		this.showView();
		this._modalsStore.hideModal();
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.checkTeamChangedState();
		const subscription$ = reaction(
			() => this.team,
			() => this.checkTeamChangedState()
		);
		this.subscriptions$.push(subscription$);
	}

	onChange(param: void): void {
		return;
	}

	private checkTeamChangedState(): void {
		if (this.team.auto === undefined || !this.raceId) {
			return;
		}

		if (this.team.auto === true) {
			this._modalsStore.showModal(ModalType.TEAM_CHANGED);
		}
	}

	private showView() {
		if (!this.raceId) {
			return;
		}

		this._teamStore.viewTeamChanged(this.raceId).catch((error: AxiosError<IApiResponse>) => {
			this._modalsStore.showErrorModal(error);
		});
	}
}
