import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {ShareType, SocialNetwork} from "data/enums";
import {share} from "data/utils";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import React from "react";

interface IParams {
	type: ShareType;
	leagueId?: number;
}

export interface ISharingController extends ViewController<IParams> {
	shareViaFB: () => void;
	shareViaTW: () => void;
	shareViaMobile: () => void;
	shareHandler: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	beforeShareClose: () => void;

	get isPopoverOpen(): boolean;

	get isMobileShareVisible(): boolean;

	get isNarrowScreen(): boolean;

	get anchorEl(): HTMLButtonElement | null;
}

@injectable()
export class SharingController implements ISharingController {
	@observable private _anchorEl: HTMLButtonElement | null = null;
	@observable private _isPopoverOpen: boolean = false;
	@observable private _type: ShareType = ShareType.General;
	@observable private _leagueId: number | undefined;
	@observable private _isNarrowScreen: boolean = false;

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	get isPopoverOpen(): boolean {
		return this._isPopoverOpen;
	}

	get isNarrowScreen(): boolean {
		return this._isNarrowScreen;
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 840px)");
	}

	get anchorEl(): HTMLButtonElement | null {
		return this._anchorEl;
	}

	dispose(): void {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);
	}

	@action
	public setAnchorEl = (event: React.SyntheticEvent<HTMLButtonElement> | null) => {
		this._anchorEl = event?.currentTarget || null;
	};

	init(param: IParams): void {
		this._type = param.type;
		this._leagueId = param.leagueId;
		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	onChange(param: IParams): void {
		this._type = param.type;
		this._leagueId = param.leagueId;
	}

	shareViaFB = () => this.share(SocialNetwork.Facebook);

	shareViaTW = () => this.share(SocialNetwork.Twitter);

	shareViaMobile = () => this.share(SocialNetwork.Mobile);

	shareHandler = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		if (this.isMobileShareVisible) {
			this.shareViaMobile();
		} else {
			this.setAnchorEl(event);
			this.openSharePopup();
		}
	};

	@action
	public beforeShareClose = () => {
		this.setAnchorEl(null);
		this.popupClose();
	};

	private share(socialNetwork: SocialNetwork) {
		if (this._type === ShareType.League && this._leagueId) {
			share({
				socialNetwork,
				type: this._type,
				leagueId: this._leagueId,
				message: this.i18n.t(
					"sharing.message.league",
					"Come and play Crown Jewels Fantasy"
				),
			});
			return;
		}

		share({
			socialNetwork,
			type: this._type,
			message: this.i18n.t("sharing.message.general", "Come and play Crown Jewels Fantasy"),
		});
	}

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	@action
	private popupClose() {
		this._isPopoverOpen = false;
	}

	@action
	private openSharePopup() {
		this._isPopoverOpen = true;
	}
}
