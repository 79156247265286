import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {NavigateFunction} from "react-router-dom";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParams {
	navigate: NavigateFunction;
}

export interface IModalNoRacesController extends ViewController<IParams> {
	toJoin: () => void;
	toCreate: () => void;
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;
}

@injectable()
export class ModalNoRacesController implements IModalNoRacesController {
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.NO_RACES;
	}

	public toJoin = () => {
		this._navigate?.("/leagues/join");
		this.close();
	};

	public toCreate = () => {
		this._navigate?.("/leagues/create");
		this.close();
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
	}

	onChange(param: IParams): void {
		this._navigate = param.navigate;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};
}
