import React from "react";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {RaceStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IEvent, IEventsStore} from "data/stores/events/events.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IRaceScheduleController extends ViewController {
	getRaceClass: (event: IEvent) => string;
	close: () => void;
	navigateToRace: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	get i18n(): ILocalizationStore;

	get events(): IEvent[];

	get isOpen(): boolean;
}

@injectable()
export class RaceScheduleController implements IRaceScheduleController {
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._eventsStore.isScheduleOpen;
	}

	get events() {
		return this._eventsStore.events;
	}

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
		document.body.classList.remove("overflow");
	}

	init(param: void): void {
		void this._eventsStore.fetchEvents();

		const subscription = reaction(
			() => this._eventsStore.isScheduleOpen,
			() => this.checkBodyClass(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	private checkEventFromCMSRace(event: IEvent | undefined) {
		if (!event) {
			return false;
		}
		const race = this._racesStore.CMSRace;
		return race?.title === event.name;
	}

	public getRaceClass = (event: IEvent) => {
		const race = this._racesStore.getRaceById(event?.race || -1);
		let className = "";

		if (this.checkEventFromCMSRace(event)) {
			return "active";
		}

		if (!race) {
			return "";
		}

		if (race.status === RaceStatus.Complete) {
			className += "complete ";
		}

		if ([RaceStatus.Scheduled, RaceStatus.Playing].includes(race.status)) {
			className += "active";
		}

		return className;
	};

	public close = () => {
		this._eventsStore.isScheduleOpen = false;
	};

	public navigateToRace = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
		const raceId = Number(event.currentTarget.dataset.race);
		const eventName = String(event.currentTarget.dataset.event_name);
		const race = this._racesStore.getRaceById(raceId);

		const eventEntity = this._eventsStore.getEventByName(eventName);
		if (this.checkEventFromCMSRace(eventEntity)) {
			this._racesStore.selectedRace = this._racesStore.CMSRace;
			this._horsesStore.clearHorses();
			this._teamStore.clearTeam();
			this.close();
		}

		if (!race) {
			return;
		}

		if (this._racesStore.selectedRace?.id === race.id) {
			this.close();
			return;
		}

		this._racesStore.selectedRace = race;
		this._horsesStore.clearHorses();
		this._teamStore.clearTeam();
		this.close();
	};

	private checkBodyClass() {
		document.body.classList.remove("overflow");

		if (this._eventsStore.isScheduleOpen) {
			document.body.classList.add("overflow");
		} else {
			document.body.classList.remove("overflow");
		}
	}
}
