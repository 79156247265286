export abstract class ScrollHelper {
	public static readonly CAPTAIN_KEY = "hba_cap_view";

	public static SCROLL_TO(elementId: string, offsetTop = 0): void {
		const element = document.getElementById(elementId);

		if (!element) {
			return;
		}

		const rect = element.getBoundingClientRect();

		window.scrollTo({
			top: rect.top + offsetTop,
			behavior: "smooth",
		});
	}

	public static TIE_BREAKER_SCROLL(): void {
		this.SCROLL_TO("tie-breaker", 60);
	}
	public static CAPTAIN_SCROLL(): void {
		this.SCROLL_TO("captain-button", -120);
	}
}
