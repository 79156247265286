import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {AxiosResponse} from "axios";
import {RankingsSortValue, SortOrder} from "data/enums";
import {IGameBarData} from "data/stores/game_bar/game_bar.store";

export interface IRanking {
	userId: number | null;
	displayName: string;
	leagueId: number | null;
	rank: number | null;
	points: number | null;
	overallRank: number | null;
	overallPoints: number | null;
	userCountry: string;
	pinnedLink: string;
}

export interface IRankingsPayload {
	raceFeedId: number | null;
	page?: number;
	limit?: number;
	order?: RankingsSortValue;
	dir?: SortOrder;
}

export interface ILeagueRankingsPayload {
	raceFeedId: number | null;
	leagueId: number;
	page?: number;
	limit?: number;
}

export interface IRankingsResponse {
	rankings: IRanking[];
	user: IRanking | null;
	nextPage: boolean;
}

export interface ILeagueRankingsResponse extends IRankingsResponse {
	pinnedUsers: IRanking[];
}

export type TRankingsResponse = IApiResponse<IRankingsResponse>;
export type TLeagueRankingsResponse = IApiResponse<ILeagueRankingsResponse>;
export type TGameBarResponse = IApiResponse<IGameBarData>;

export interface IRankingsApiProvider {
	gameBar: () => Promise<AxiosResponse<TGameBarResponse>>;
	rankings: (payload: IRankingsPayload) => Promise<AxiosResponse<TRankingsResponse>>;
	leagueRankings: (
		payload: ILeagueRankingsPayload
	) => Promise<AxiosResponse<TLeagueRankingsResponse>>;
}

@injectable()
export class RankingsApiProvider implements IRankingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	gameBar(): Promise<AxiosResponse<TGameBarResponse>> {
		return this._http.get<TGameBarResponse>("fantasy/game-bar");
	}

	rankings(payload: IRankingsPayload): Promise<AxiosResponse<TRankingsResponse>> {
		return this._http.get<TRankingsResponse>("fantasy/ranking", payload);
	}

	leagueRankings(
		payload: ILeagueRankingsPayload
	): Promise<AxiosResponse<TLeagueRankingsResponse>> {
		const {leagueId, ...rest} = payload;
		return this._http.get<TLeagueRankingsResponse>(`fantasy/ranking/league/${leagueId}`, rest);
	}
}
