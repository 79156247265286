import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaguesStore, ILeagueUsers} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {isEqual} from "lodash";
import {LeagueStatus, ModalType, RequestState} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

interface IParams {
	leagueId: number;
}

export interface ILeagueUsersController extends ViewController<IParams> {
	getRowClassName: (userId: number) => string;
	getIsRowRemovable: (userId: number) => boolean;
	removeUser: (userId: number) => void;
	loadMoreUsers: () => void;

	get i18n(): ILocalizationStore;

	get leagueUsers(): ILeagueUsers | null;

	get isLeagueUsersRequestLoading(): boolean;
}

@injectable()
export class LeagueUsersController implements ILeagueUsersController {
	@observable private _leagueId: number = Number.MAX_SAFE_INTEGER;
	@observable private _requestsStates = {
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isLeagueUsersRequestLoading() {
		return isEqual(this._requestsStates.leagueUsers, RequestState.PENDING);
	}

	get leagueUsers() {
		if (!this.league) return null;
		return this._leaguesStore.getLeagueUsersByLeagueId(this.league.id);
	}

	private get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	private get userID() {
		return this._userStore.user!.id;
	}

	private get leagueID() {
		return this._leagueId;
	}

	public getRowClassName = (userId: number) => {
		return this.getIsUserRow(userId) ? "user" : "";
	};

	public getIsRowRemovable = (userId: number) => {
		return !this.isLeagueStarted && !this.getIsUserRow(userId);
	};

	@action
	public removeUser = (userId: number) => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league_setting.manage_user.confirm_remove",
				"Do you really want to remove this user from the league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("removeUser");

				this._leaguesStore
					.removeUserFromLeague({userId, leagueId: this.leagueID})
					.then(this.createSuccessHandlerOf("removeUser"))
					.catch(this.createErrorHandlerOf("removeUser"));
			},
		});
	};

	dispose(): void {
		return;
	}

	@action init({leagueId}: IParams) {
		this._leagueId = leagueId;

		Promise.all([this._leaguesStore.fetchLeagueUsers({leagueId})])
			.then(this.createSuccessHandlerOf("leagueUsers"))
			.catch(this.createErrorHandlerOf("leagueUsers"));
	}

	onChange(param: IParams): void {
		return;
	}

	@action
	public loadMoreUsers = () => {
		this.setPendingState("leagueUsers");

		this._leaguesStore
			.fetchMoreLeagueUsers({leagueId: this.leagueID})
			.then(this.createSuccessHandlerOf("leagueUsers"))
			.catch(this.createErrorHandlerOf("leagueUsers"));
	};

	private getIsUserRow(userId: number): boolean {
		return this.userID === userId;
	}

	@action
	private createErrorHandlerOf =
		(type: keyof typeof this._requestsStates) => (error: AxiosError<IApiResponse>) => {
			this._requestsStates[type] = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		};

	@action
	private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => () => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action
	private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};
}
