import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {NavigateFunction} from "react-router-dom";

interface IProps {
	code: string | undefined;
	navigate: NavigateFunction;
}

export interface IModalLeagueInvitedController extends ViewController<IProps> {
	close: () => void;
	acceptInvite: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isLoading(): boolean;

	get league(): ILeague | undefined;
}

@injectable()
export class ModalLeagueInvitedController implements IModalLeagueInvitedController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _code: string | undefined;
	@observable private _isOpen: boolean = false;
	@observable private _league: ILeague | undefined;
	@observable private _isLoading: boolean = false;
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	get league(): ILeague | undefined {
		return this._league;
	}

	public close = () => {
		this._isOpen = false;
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IProps): void {
		this._code = param.code;
		this._navigate = param.navigate;

		this.checkCodeAndFetch();
		const subscription = reaction(
			() => this._code,
			() => this.checkCodeAndFetch()
		);

		this.subscriptions$.push(subscription);
	}

	onChange(param: IProps): void {
		this._code = param.code;
		this._navigate = param.navigate;
	}

	@action
	public acceptInvite = () => {
		const code = this._code;
		if (!code) {
			return;
		}

		this._isLoading = true;
		this._leaguesStore
			.joinToLeague({code})
			.then(() => this.redirectToMyLeagueByCode())
			.catch(this.onError.bind(this))
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	private checkCodeAndFetch() {
		const code = this._code;
		if (!code) {
			return;
		}
		this._isOpen = true;
		this._leaguesStore
			.fetchLeagueByCode({code})
			.then((league) => {
				runInAction(() => {
					this._league = league;
					if (league.isJoined) {
						this.redirectToMyLeagueByCode();
					}
				});
			})
			.catch(this.onError.bind(this));
	}

	private onError(error: AxiosError<IApiResponse>) {
		this._modalsStore.showErrorModal(error);
	}

	private redirectToMyLeagueByCode = () => {
		if (this.league) {
			this._isOpen = false;
			this._navigate?.(`/league/${this.league.id}/`, {replace: false});
		}
	};
}
