import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {isNullOrUndefined} from "data/utils";
import {TIE_BREAKER_CORRECT_POINTS, TIE_BREAKER_NAMES} from "data/constants";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorse} from "data/types/race";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeam} from "data/types/team";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRivalTeamStore} from "data/stores/rival_team/rival_team.store";
import {get} from "lodash";

interface IParams {
	team?: ITeam;
	isRival?: boolean;
}
export interface ITieBreakerScoreController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get isCorrect(): boolean;

	get points(): number;

	get firstHorse(): IHorse | undefined;

	get raceDistance(): number | undefined | null;

	get preparedUserDistance(): string;

	get scoreClass(): string;

	get isCompleteButNoScored(): boolean;

	get preparedLength(): string;
}

@injectable()
export class TieBreakerScoreController implements ITieBreakerScoreController {
	@observable private _team: ITeam | undefined;
	@observable private _isRival: boolean = false;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.RivalTeamStore) private _rivalTeamStore: IRivalTeamStore
	) {
		makeAutoObservable(this);
	}

	get preparedLength(): string {
		return this.prepareDistanceString(this.raceDistance);
	}

	get preparedUserDistance(): string {
		return this.prepareDistanceString(this.userDistance);
	}

	private prepareDistanceString(distance: number | null | undefined) {
		if (distance === undefined || distance === null) {
			return this.i18n.t("tiebreaker.value.none", "None");
		}

		if (distance > 10) {
			return this.i18n.t("tiebreaker.value.maximum", "10+ Lengths");
		}

		if (distance > 1) {
			return this.i18n.t("tiebreaker.value.length", "{{X}} Lengths", {
				X: distance,
			});
		}

		if (distance >= 0.5) {
			return this.i18n.t("tiebreaker.value.length_singular", "{{X}} Length", {
				X: distance,
			});
		}

		return this.i18n.t(get(TIE_BREAKER_NAMES, distance, ""));
	}

	get isCorrect(): boolean {
		if (isNullOrUndefined(this.userDistance) || isNullOrUndefined(this.raceDistance)) {
			return false;
		}

		return this.userDistance === this.raceDistance;
	}

	get points(): number {
		return this.isCorrect ? TIE_BREAKER_CORRECT_POINTS : 0;
	}

	get scoreClass(): string {
		return this.isCorrect ? "" : "incorrect";
	}

	get firstHorse(): IHorse | undefined {
		if (this._team || this._isRival) {
			return this._rivalTeamStore.horses?.find((horse) => horse.finishedPosition === 1);
		}
		return this._horsesStore.list.find((horse) => horse.finishedPosition === 1);
	}

	get raceDistance() {
		if (this._isRival) {
			return this._racesStore.getRaceById(this._rivalTeamStore.raceId)?.winningDistance;
		}
		return this._racesStore.selectedRace?.winningDistance;
	}

	get isCompleteButNoScored() {
		const isNoScore = !this.userDistance;
		const isComplete = this._racesStore.isRaceComplete;

		return isNoScore && isComplete;
	}

	private get team(): ITeam {
		return this._team || (this._teamStore.team as ITeam);
	}

	get userDistance(): number | undefined | null {
		return this.team?.winningDistance;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._team = param.team;
		this._isRival = param.isRival || false;
	}

	onChange(param: IParams): void {
		this._team = param.team;
		this._isRival = param.isRival || false;
	}
}
