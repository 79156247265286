import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

import stepTwoImage from "assets/img/tutorial/step-2.png";
import stepThreeImage from "assets/img/tutorial/step-3.png";
import stepFourImage from "assets/img/tutorial/step-4.png";
import stepFiveImage from "assets/img/tutorial/step-5.png";
import stepSixImage from "assets/img/tutorial/step-6.png";
import stepSevenImage from "assets/img/tutorial/step-7.png";

export interface ITutorialStep {
	title_loco: string;
	title_alt: string;
	text_loco: string;
	text_alt: string;
	image?: string;
	hasButton?: boolean;
}

export interface ITutorialStore {
	get steps(): ITutorialStep[];

	get isTutorialOpen(): boolean;

	openTutorialModal(): void;

	getIsTutorialViewedStorage(): boolean;

	clearTutorialStorage(): void;

	closeTutorialModal(): void;
}

const STORAGE_KEY = "hba_tutorial";

@injectable()
export class TutorialStore implements ITutorialStore {
	@observable private _isTutorialOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	public get isTutorialOpen(): boolean {
		return this._isTutorialOpen;
	}

	public get steps(): ITutorialStep[] {
		return [
			{
				title_loco: "tutorial.step_2.title",
				title_alt: "Build Your Team",
				text_loco: "tutorial.step_2.text",
				image: stepTwoImage,
				text_alt:
					"<p>To get started, you need to select <b>3 horses</b> from the horse pool.</p>" +
					"<p>Make your <b>tie-breaker</b> prediction to earn extra points.</p>" +
					"<p>Each event will require you to select a whole new team.</p>",
			},
			{
				title_loco: "tutorial.step_3.title",
				title_alt: "Horse Scoring Value",
				text_loco: "tutorial.step_3.text",
				image: stepThreeImage,
				text_alt:
					"<p>Each horse is assigned a 'Horse Scoring Value' based on how well they are likely to perform in the race:</p>" +
					"<p>1. The lower the HSV the more likely this horse is to win/place in the race.</p>" +
					"<p>2. The higher the HSV means this horse is most likely an 'underdog' in the race.</p>",
			},
			{
				title_loco: "tutorial.step_4.title",
				title_alt: "Choose Your Captain",
				text_loco: "tutorial.step_4.text",
				text_alt:
					"<p>Designate one of your horses as your captain - their points will be doubled. You can change your captain as many times as you like up to the scheduled lockout time.</p>",
				image: stepFourImage,
			},
			{
				title_loco: "tutorial.step_5.title",
				title_alt: "Donkey Insurance",
				text_loco: "tutorial.step_5.text",
				text_alt:
					"<p>Use this booster on one of your picks and get bonus points if that horse finishes last!</p>" +
					"<p>If the horse finishes last you will score <b>100 Pts</b></p>" +
					"<p>You can only activate this three times across the season - so use it wisely!</p>",
				image: stepFiveImage,
			},
			{
				title_loco: "tutorial.step_6.title",
				title_alt: "Earn Points",
				text_loco: "tutorial.step_6.text",
				text_alt:
					"<p>Users will earn multipliers on the Horse Scoring Value based on where their picks finish. Example:</p>" +
					"<p><b>The Big Dog</b> has an <b>HSV of 25</b></p>" +
					"<p>As this horse was picked to finish first & did - the <b>HSV (25)</b> has a 4x multiplier = <b>100 pts</b></p>" +
					"<p>This horse was also made <b>Captain</b> so a further <b>2x multiplier</b> is awarder = <b>200 pts</b></p>",
				image: stepSixImage,
			},
			{
				title_loco: "tutorial.step_7.title",
				title_alt: "Leaderboards",
				text_loco: "tutorial.step_7.text",
				text_alt:
					"<p>Compete in the Event and Overall Leaderboards and challenge users Globally for your chance to win prizes.</p>",
				image: stepSevenImage,
			},
		];
	}

	public closeTutorialModal(): void {
		localStorage.setItem(STORAGE_KEY, "true");
		this._isTutorialOpen = false;
	}

	public openTutorialModal(): void {
		this._isTutorialOpen = true;
	}

	public getIsTutorialViewedStorage(): boolean {
		return JSON.parse(localStorage.getItem(STORAGE_KEY) || "false") as boolean;
	}

	public clearTutorialStorage(): void {
		localStorage.removeItem(STORAGE_KEY);
	}
}
