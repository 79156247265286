import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";

import {ReactComponent as ModalLogo} from "assets/img/logo/modal_logo.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import {getIsIOS} from "data/utils";
import {ModalCloseBlock, ModalContent} from "views/components/modals/styled";
import {Button, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useViewController} from "data/services/locator";
import {IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import {TutorialSteps} from "views/components/tutorial_steps/tutorial_steps.component";
import {ILocalizationStore} from "data/stores/localization/localization.store";
import CheckIcon from "@mui/icons-material/Check";

const SliderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.start {
		width: calc(100% - 40px);
		margin: auto auto 0 auto;
	}

	.slick-arrow {
		display: none !important;
	}
`;

const StepContent = styled.div`
	> div {
		padding: 0 12px 0 12px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

	&.non-step {
		padding-top: 90px;
	}

	img {
		width: 100%;
		height: auto;
		margin-bottom: 16px;
	}

	.text {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;

		color: var(--primaryColorDark);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 19.6px */

		b {
			font-weight: bold;
		}

		p {
			color: var(--primaryColorDark);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 19.6px */

			b {
				font-weight: bold;
			}
		}
	}

	h3 {
		color: var(--primaryColorDark);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		text-transform: capitalize;
		margin-bottom: 8px;
	}

	button {
		&:first-of-type {
			margin-top: 16px;
		}
		margin-bottom: 8px;
		max-width: 320px;
	}
`;

const SModalContent = styled(ModalContent)`
	max-width: 450px;
	padding: 0;
	@media screen and (max-width: 640px) {
		min-height: 88vh;
	}
`;

const SModalCloseBlock = styled(ModalCloseBlock)`
	padding: 12px 12px 0 12px;
`;

export const ModalTutorial: React.FC = observer(() => {
	const sliderRef = useRef<Slider>(null);
	const {
		i18n,
		isOpen,
		steps,
		currentStep,
		isStepsVisible,
		onReplay,
		onPlay,
		goToFirstStep,
		goToStep,
		goToStepFromSteps,
		onClose,
	} = useViewController<IModalTutorialController>(Bindings.ModalTutorialController);

	useEffect(() => {
		if (sliderRef?.current) {
			sliderRef?.current?.slickGoTo(currentStep);
		}
	}, [currentStep, sliderRef]);

	return (
		<Modal open={isOpen}>
			<SModalContent>
				<SModalCloseBlock>
					<Button aria-label="close" sx={{minWidth: 0}} onClick={onClose}>
						<CloseIcon />
					</Button>
				</SModalCloseBlock>
				<SliderWrapper>
					<Slider
						ref={sliderRef}
						adaptiveHeight={false}
						dots={false}
						infinite={false}
						speed={500}
						slidesToScroll={1}
						slidesToShow={1}
						useTransform={getIsIOS()}
						beforeChange={goToStep}>
						<FirstStep i18n={i18n} onClose={onClose} onPlay={onPlay} />
						{steps.map((step, index) => (
							<StepContent key={index}>
								<Exist when={Boolean(step.image)}>
									<img src={step.image} alt={`step-${index}`} />
								</Exist>
								<h3
									// Disabled due html come from our BE and must be safety
									// eslint-disable-next-line
									dangerouslySetInnerHTML={{
										__html: i18n.t(step.title_loco, step.title_alt),
									}}
								/>

								<div
									className="text"
									// Disabled due html come from our BE and must be safety
									// eslint-disable-next-line
									dangerouslySetInnerHTML={{
										__html: i18n.t(step.text_loco, step.text_alt),
									}}
								/>

								<Exist when={currentStep === 0 && step.hasButton}>
									<Button onClick={goToFirstStep} fullWidth>
										{i18n.t("tutorial.step_1.button_next", "next")}
									</Button>
								</Exist>
								<Exist when={currentStep === steps.length - 1 && step.hasButton}>
									<Button onClick={onClose} fullWidth>
										{i18n.t("tutorial.step_6.button_play")}
									</Button>
								</Exist>
							</StepContent>
						))}
						<LastStep i18n={i18n} onClose={onClose} onReplay={onReplay} />
					</Slider>
					<Exist when={isStepsVisible}>
						<TutorialSteps
							currentStep={currentStep}
							steps={steps}
							goToStep={goToStepFromSteps}
						/>
					</Exist>
				</SliderWrapper>
			</SModalContent>
		</Modal>
	);
});

interface IProps {
	i18n: ILocalizationStore;
	onPlay: () => void;
	onClose: () => void;
}

const FirstStep: React.FC<IProps> = observer(({i18n, onClose, onPlay}) => {
	return (
		<StepContent className="non-step">
			<ModalLogo />
			<h3
				// Disabled due html come from our BE and must be safety
				// eslint-disable-next-line
				dangerouslySetInnerHTML={{
					__html: i18n.t(
						"tutorial.step_1.title",
						"Welcome to<br/>Crown Jewels Racing Fantasy"
					),
				}}
			/>

			<div
				className="text"
				// Disabled due html come from our BE and must be safety
				// eslint-disable-next-line
				dangerouslySetInnerHTML={{
					__html: i18n.t(
						"tutorial.step_1.text",
						"The official fantasy game of<br/>Crown Jewels Racing."
					),
				}}
			/>

			<Button onClick={onPlay} variant="outlined" fullWidth>
				{i18n.t("tutorial.step_1.view", "View tutorial")}
			</Button>
			<Button onClick={onClose} fullWidth>
				{i18n.t("tutorial.step_1.play", "Play now")}
			</Button>
		</StepContent>
	);
});

interface IFinalProps {
	i18n: ILocalizationStore;
	onClose: () => void;
	onReplay: () => void;
}

const FinalStepLogo = styled.div`
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	margin: 0 auto 20px auto;
	border-radius: 50%;
	background: var(--iconColorPrimaryDark);

	svg {
		width: 75%;
		height: auto;

		path {
			fill: #ffffff;
		}
	}
`;

const LastStep: React.FC<IFinalProps> = observer(({i18n, onReplay, onClose}) => {
	return (
		<StepContent className="non-step">
			<FinalStepLogo>
				<CheckIcon />
			</FinalStepLogo>
			<h3
				// Disabled due html come from our BE and must be safety
				// eslint-disable-next-line
				dangerouslySetInnerHTML={{
					__html: i18n.t("tutorial.step_final.title", "You’re Ready!"),
				}}
			/>

			<div
				className="text"
				// Disabled due html come from our BE and must be safety
				// eslint-disable-next-line
				dangerouslySetInnerHTML={{
					__html: i18n.t(
						"tutorial.step_final.text",
						"What are you waiting for?<br/>Pick your team now!"
					),
				}}
			/>

			<Button onClick={onReplay} variant="outlined" fullWidth>
				{i18n.t("tutorial.step_final.replay", "Replay tutorial")}
			</Button>
			<Button onClick={onClose} fullWidth>
				{i18n.t("tutorial.step_final.play", "Get started")}
			</Button>
		</StepContent>
	);
});
