import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RaceStatus} from "data/enums";
import type {IRacesStore} from "data/stores/races/races.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRacePoints, ITeam} from "data/types/team";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameBarStore} from "data/stores/game_bar/game_bar.store";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";

export interface IRacePointsController extends ViewController {
	get i18n(): ILocalizationStore;

	get isVisible(): boolean;

	get isCMSRace(): boolean;

	get points(): IRacePoints;
}

@injectable()
export class RacePointsController implements IRacePointsController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get points(): IRacePoints {
		return {
			racePoints: this.team.racePoints,
			totalPoints: this.gameBar?.overallPoints,
			rank: this.gameBar?.overallRank,
			raceRank: this.team.raceRank,
		};
	}

	get isVisible(): boolean {
		if (!this.race) {
			return false;
		}
		return this.race.status !== RaceStatus.Scheduled;
	}

	get isCMSRace(): boolean {
		return this._racesStore.isCMSRace;
	}

	private get gameBar() {
		return this._gameBarStore.gameBar;
	}

	private get team(): ITeam {
		return this._teamStore.team as ITeam;
	}

	private get race() {
		return this._racesStore.selectedRace;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._gameBarStore.fetchGameBar().catch((error: AxiosError<IApiResponse>) => {
			this._modalsStore.showErrorModal(error);
		});
	}

	onChange(param: void): void {
		return;
	}
}
