import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType} from "data/enums";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {find, first, uniqBy} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import React from "react";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IJoinLeaguesController extends ViewController<IProps> {
	loadMoreMyLeagues: () => void;
	getLeagueClass: (id: number) => string;
	getLeagueNewsLink: (id: number) => string | undefined;
	joinLeague: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	isJoinInProgress: (id: number) => boolean;

	get i18n(): ILocalizationStore;

	get leaguesToShow(): ILeague[];

	get nextPage(): boolean;

	get isLoading(): boolean;

	get hasLeagues(): boolean;
}

@injectable()
export class JoinLeaguesController implements IJoinLeaguesController {
	@observable _joiningLeagueId?: number;
	@observable _navigate!: ReturnType<typeof useNavigate>;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get leaguesToShow() {
		const {leagues, pinned} = this._leaguesStore.leaguesForJoin;
		return uniqBy([...pinned, ...leagues], "id");
	}

	get nextPage() {
		return this._leaguesStore.leaguesForJoin.nextPage;
	}

	get isLoading() {
		return this._leaguesStore.isLoadingJoinLeagues;
	}

	get hasLeagues() {
		return this.leaguesToShow.length > 0;
	}

	dispose(): void {
		return;
	}

	onChange(param: IProps): void {
		return;
	}

	loadMoreMyLeagues = () => {
		this._leaguesStore.fetchMoreLeaguesForJoin().catch(this.onError);
	};

	@action joinLeague = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		const id = Number(event.currentTarget.dataset.id);
		const code = find(this.leaguesToShow, {id})?.code;

		if (!code) return;

		this._joiningLeagueId = id;

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this.redirectToMyLeagueByCode({code}))
			.catch(this.onError);
	};

	isJoinInProgress = (id: number) => {
		return this._joiningLeagueId === id;
	};

	@action init({navigate}: IProps) {
		this._navigate = navigate;

		this._leaguesStore.fetchLeaguesForJoin().catch(this.onError);
	}

	public getLeagueClass = (id: number) => {
		const league = this.leaguesToShow.find((e) => e.id === id);
		return league?.isPinned ? "celebrity" : "";
	};

	public getLeagueNewsLink = (id: number) => {
		const league = this.leaguesToShow.find((e) => e.id === id);
		const firstPinnedUser = first(league?.pinnedUsers);
		return firstPinnedUser?.link;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._joiningLeagueId = undefined;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private redirectToMyLeagueByCode = ({
		code,
		replace = false,
	}: {
		code: string;
		replace?: boolean;
	}) => {
		const {leagues} = this._leaguesStore.myLeagues;
		const id = find(leagues, {code})?.id;
		if (id) this._navigate(`/league/${id}/`, {replace});
	};
}
