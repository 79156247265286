import {retryFailLoad} from "data/utils";
import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const LandingPage = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route path="*" element={<LandingPage />} />
	</Routes>
);

export default SecretGateRoutes;
