import React from "react";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {HorseInfoBlock, ModalType, RaceStatus} from "data/enums";
import type {IHorse} from "data/types/race";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorsePointsStore} from "data/stores/horse_points/horse_points.store";
import {AxiosError} from "axios/index";
import {IApiResponse} from "data/services/http";

interface IModalContent {
	horseId: number;
}

export interface IModalHorseController extends ViewController {
	toggleBlock: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	getIsBlockActive: (block: HorseInfoBlock) => boolean;
	getIsBlockActiveClassName: (block: HorseInfoBlock) => string;
	close: () => void;

	get i18n(): ILocalizationStore;

	get isRaceCanBeViewed(): boolean;

	get isRaceComplete(): boolean;

	get isOpen(): boolean;

	get isCaptain(): boolean;

	get horse(): IHorse | undefined;
}

@injectable()
export class ModalHorseController implements IModalHorseController {
	@observable private _activeBlock: HorseInfoBlock = HorseInfoBlock.Stats;
	@observable private subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.HorsePointsStore) private _horsePointsStore: IHorsePointsStore
	) {
		makeAutoObservable(this);
	}

	get horse(): IHorse | undefined {
		if (!this.horseId) {
			return;
		}
		return this._horsesStore.getHorseById(this.horseId);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.HORSE_INFO;
	}

	get isCaptain(): boolean {
		return this._teamStore.team.captain === this.horse?.id;
	}

	get isRaceCanBeViewed(): boolean {
		const keys = Object.keys(this._horsePointsStore.horsePoints?.races || {});
		const completeIds = keys.filter((key) => {
			const race = this._racesStore.getRaceById(Number(key));
			return race?.status === RaceStatus.Complete;
		});
		return completeIds.length > 0;
	}

	get isRaceComplete(): boolean {
		return this._racesStore.selectedRace?.status === RaceStatus.Complete;
	}

	private get horseId(): number | undefined {
		const content = this._modalsStore.modalContent as IModalContent;
		return content?.horseId;
	}

	@action
	public toggleBlock = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		const block = event.currentTarget.dataset.block as HorseInfoBlock;
		if (!block) {
			return;
		}
		this._activeBlock = block;
	};

	public getIsBlockActive = (block: HorseInfoBlock) => {
		return block === this._activeBlock;
	};

	public getIsBlockActiveClassName = (block: HorseInfoBlock) => {
		return this.getIsBlockActive(block) ? "active" : "";
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.fetchRacePoints();
		const subscription$ = reaction(
			() => [this.horse, this._horsePointsStore.horsePoints],
			() => this.fetchRacePoints()
		);
		this.subscriptions$.push(subscription$);
	}

	onChange(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
		this._activeBlock = HorseInfoBlock.Stats;
		this._horsePointsStore.clearPoints();
	};

	@action
	private fetchRacePoints() {
		const horse = this.horse;
		const pointsEntity = this._horsePointsStore.horsePoints;

		// To prevent multiple request when info is already fetched
		if (!horse || Boolean(pointsEntity)) {
			return;
		}
		this._horsePointsStore
			.fetchHorsePoints(horse.feedId)
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error));
	}
}
