import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Container} from "@mui/material";
import {ReactComponent as GSLogo} from "assets/img/logo/GS_logo.svg";
import {ReactComponent as GameLogo} from "assets/img/logo/logo_white.svg";
import {NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {TERMS_LINK} from "data/constants";

const Wrapper = styled.footer`
	width: 100%;
	height: 120px;
	padding: 20px 0;
	background: var(--primaryColorDark);
	@media screen and (max-width: 840px) {
		height: unset;
		padding-bottom: 90px;
	}
`;

const Content = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	@media screen and (max-width: 840px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
	}
`;

const Rights = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 8px;

	svg {
		height: 100%;
		width: auto;
	}

	p {
		color: #ffffff;
		font-size: 12px;
		font-style: normal;
		font-weight: 450;
		line-height: 140%; /* 16.8px */
	}
`;
const Links = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	> div {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 12px;
	}

	a,
	span {
		color: #ffffff;
		text-align: right;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 14px */
	}

	a {
		font-size: 14px;
		text-decoration-line: underline;
	}

	button {
		font-size: 14px !important;
		padding: 0 !important;
		text-decoration-line: underline;
		color: #ffffff !important;
		border: none !important;
		text-align: right;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 14px */
		background: transparent;

		&:hover {
			background: #ffffff !important;
			color: var(--primaryColorDark) !important;
		}
	}

	@media screen and (max-width: 840px) {
		gap: 12px;
		align-items: center;
		justify-content: center;
	}
`;

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	return (
		<Wrapper>
			<Content>
				<Rights>
					<GameLogo />
					<p>{i18n.t("footer.copy.copyright", "© 2024 Crown Jewels Racing.")}</p>
				</Rights>
				<Links>
					<div>
						<button id="ot-sdk-btn" className="ot-sdk-show-settings">
							Cookie Settings
						</button>
						<NavLink to={TERMS_LINK}>
							{i18n.t("footer.copy.terms", "Terms & Conditions")}
						</NavLink>
						<NavLink to="/help/contact-us">
							{i18n.t("footer.copy.contact", "Contact Us")}
						</NavLink>
					</div>
					<div>
						<span>{i18n.t("footer.copy.powered", "Powered By:")}</span>
						<GSLogo />
					</div>
				</Links>
			</Content>
		</Wrapper>
	);
});
