import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import {noop} from "lodash";

export interface INotificationController extends ViewController {
	get notification(): string | undefined;
}

@injectable()
export class NotificationController implements INotificationController {
	constructor(@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore) {
		makeAutoObservable(this);
	}

	get notification(): string | undefined {
		return this._settingsStore.notification;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._settingsStore.fetchSettings().catch(noop);
	}

	onChange(param: void): void {
		return;
	}
}
