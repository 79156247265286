import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {identity, isEqual, values} from "lodash";
import {LeaguePrivacy, LeagueStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ChangeEvent} from "react";
import {IUpdateLeaguePayload} from "data/providers/api/leagues.api.provider";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";

interface IFormValue {
	leagueName: string;
	privacy: LeaguePrivacy;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IProps {
	leagueId: number;
}

export interface ISectionLeagueSettingsController extends ViewController<IProps> {
	handleUpdateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;

	get i18n(): ILocalizationStore;

	get formValue(): IFormValue;

	get isUpdateButtonDisabled(): boolean;

	get isFormDisabled(): boolean;

	get isLoading(): boolean;

	get isLeagueNameFieldDisabled(): boolean;
}

@injectable()
export class SectionLeagueSettingsController implements ISectionLeagueSettingsController {
	@observable private _isLoading: boolean = false;
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;

	@observable private _formValue: IFormValue = {
		leagueName: "",
		privacy: LeaguePrivacy.PRIVATE,
	};

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isFormChanged() {
		const payload = {
			leagueName: this.league?.name,
			privacy: this.league?.privacy,
		};
		return !isEqual(this.formValue, payload);
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return this._isLoading;
	}

	get isFormDisabled() {
		return this.isLoading;
	}

	get isUpdateButtonDisabled() {
		if (this.isLeagueStarted) return !this.isFormValid;

		return this.isFormDisabled || !this.isFormValid || !this.isFormChanged;
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isLeagueNameFieldDisabled() {
		return this.isLoading;
	}

	@computed
	private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	handleUpdateLeague = () => {
		if (!this.league) {
			return;
		}
		this._isLoading = true;

		const {leagueName, privacy} = this._formValue;

		const payload: IUpdateLeaguePayload = {
			name: leagueName,
			leagueId: this.league.id,
		};

		if (!this.isLeagueStarted) {
			payload.privacy = privacy;
		}

		this._leaguesStore
			.updateLeague(payload)
			.catch((error: AxiosError<IApiResponse>) => this._modalsStore.showErrorModal(error))
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	dispose(): void {
		this._leagueDisposer?.();
	}

	init(param: IProps): void {
		this._leagueId = param.leagueId;

		this._leagueDisposer = reaction(
			() => this.league,
			() => {
				if (!this.league) return;

				const {name, privacy} = this.league;

				this._formValue.leagueName = name;
				this._formValue.privacy = privacy;
			},
			{fireImmediately: true}
		);
	}

	onChange(param: IProps): void {
		this._leagueId = param.leagueId;
	}
}
