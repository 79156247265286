import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import type {IEvent} from "data/stores/events/events.store";
import type {IFederation, IHorse, IRace} from "data/types/race";
import {Language} from "data/enums";
import {ISettings} from "data/stores/settings/settings.store";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	events(): Promise<AxiosResponse<IEvent[]>>;
	races(): Promise<AxiosResponse<IRace[]>>;
	horses(raceId: number): Promise<AxiosResponse<IHorse[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;
	common_checksums(): Promise<AxiosResponse<IChecksums>>;
	settings(): Promise<AxiosResponse<ISettings>>;
	federations(): Promise<AxiosResponse<IFederation[]>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;
	helpSections(locale: string): Promise<AxiosResponse<ISections>>;
	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	events = () => this._jsonClient.get<IEvent[]>("fantasy/events.json");
	races = () => this._jsonClient.get<IRace[]>("fantasy/races.json");
	horses = (raceId: number) => this._jsonClient.get<IHorse[]>(`fantasy/${raceId}.json`);
	federations = () => this._jsonClient.get<IFederation[]>("federations.json");
	checksums = () => this._jsonClient.get<IChecksums>("fantasy/checksums.json");
	common_checksums = () => this._jsonClient.get<IChecksums>("checksums_common.json");
	settings = () => this._jsonClient.get<ISettings>("settings.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`fantasy/loco/${locale}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`);
	helpSections = (locale: Language) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`);
	helpArticles = (locale: Language) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`);
}
