import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHttpClientService} from "data/services/http";
import type {IRacesStore} from "data/stores/races/races.store";
import {Locale} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, runInAction} from "mobx";

export interface IBootstrapController extends ViewController {
	get isReady(): boolean;
}

@injectable()
export class BootstrapController implements IBootstrapController {
	private _userLocale: Locale = navigator.language as Locale;
	private _isReady = false;

	constructor(
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	get isReady(): boolean {
		return this._isReady;
	}

	async init() {
		this.fetchJsons();
		try {
			await this._i18nStore.switchLocale({
				locale: await this.defineLocale(),
			});
		} catch (_err) {
			// Show error message to a user
		}

		// Set locale that will be appended to each request
		this._apiHTTPClient.setLocale(this._i18nStore.lang);

		runInAction(() => {
			this._isReady = true;
		});
	}

	/**
	 * The method is to define a user's locale. It can be done by:
	 * 1) navigator.language
	 * 2) Site URL
	 * 3) Some JSON or API request settings
	 * 4) Whatever else
	 */
	private async defineLocale(): Promise<Locale> {
		this._userLocale = await Promise.resolve(Locale.EN_US);
		return this._userLocale;
	}

	private fetchJsons(): void {
		void this._racesStore.fetchRaces();
	}
}
