import {IHorse} from "data/types/race";
import {IPlayerPoolStatusFilter} from "data/types/filter";
import {HorseStatsFilter, HorseStatus} from "data/enums";
import {orderBy} from "lodash";

export function sortHorses(
	horses: IHorse[],
	status: IPlayerPoolStatusFilter,
	stats: HorseStatsFilter,
	isComplete: boolean
): IHorse[] {
	if (status === "all") {
		const preSorted = orderHorsesByStats(horses, stats);
		return sortByStatus(preSorted);
	}

	const statusSorted = horses.filter((horse) => horse.status === status);
	const preSorted = orderHorsesByStats(statusSorted, stats);
	return sortByStatus(preSorted);
}

function orderHorsesByStats(horses: IHorse[], stats: HorseStatsFilter) {
	switch (stats) {
		case HorseStatsFilter.Position:
			return orderBy(horses, "finishedPosition", ["asc"]);
		case HorseStatsFilter.Selected:
			return orderBy(horses, "selection", ["desc"]);
		case HorseStatsFilter.HsvAsc:
			return orderBy(horses, "horseValueScore", "asc");
		case HorseStatsFilter.HsvDesc:
			return orderBy(horses, "horseValueScore", "desc");
		default:
			return horses;
	}
}

function sortByStatus(horses: IHorse[]): IHorse[] {
	const injured = horses.filter((horse) => horse.status !== HorseStatus.Runner);
	const nonInjured = horses.filter((horse) => horse.status === HorseStatus.Runner);

	return [...nonInjured, ...injured];
}
